import React from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import knowtexLogo from ".././assets/White Knowtex Logo.png"

import Pool from "../UserPool";
import { setLogoutUser } from "../redux/setLogoutUser";

function Sidebar({ setAuth }) {
  const dispatch = useDispatch();
  const pathName = window.location.pathname
  // console.log(`current path =`, pathName)

  const logoutUser = () => {
    const user = Pool.getCurrentUser();
    if (user) user.signOut();
    setLogoutUser(dispatch);
    localStorage.removeItem("token");
    setAuth(false);
  };

  return (
    <div className="fixed top-0 min-w-[250px] max-w-[369px] flex flex-col overflow-hidden h-screen bg-purple2">
      <div className="grow flex items-center justify-center">
        <Link to="/" className="pl-10 pr-10">
          <img alt="knowtex-logo" src={knowtexLogo}></img>
        </Link>
      </div>
      <div className="flex flex-col grow items-center justify-center">
        <ul className="h-full space-y-10 flex flex-col justify-center">
          <li className="font-light text-sm text-white">
            <Link to="/" className={pathName === "/" ? "flex items-center" : "hover:underline flex items-center"}>
              <svg className="w-[30px] h-[30px] text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                <path stroke="white" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m4 12 8-8 8 8M6 10.5V19a1 1 0 0 0 1 1h3v-3a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3h3a1 1 0 0 0 1-1v-8.5" />
              </svg>
              <p className={pathName === "/" ? "font-bold text-[24px] underline pl-4" : "text-[20px] pl-4"}>Home</p>
            </Link>
          </li>
          <li className="font-light text-sm text-white">
            <Link to="/review-note" className={pathName === "/review" ? "flex items-center" : "hover:underline flex items-center"}>
              <svg className="w-[30px] h-[30px] text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                <path stroke="white" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 4h3a1 1 0 0 1 1 1v15a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h3m0 3h6m-6 7 2 2 4-4m-5-9v4h4V3h-4Z" />
              </svg>
              <p className={pathName === "/review-note" ? "font-bold underline text-[24px] pl-4" : "text-[20px] pl-4"}>Review Note</p>              </Link>
          </li>
          <li className="font-light text-sm text-white">
            <Link to="/review-transcript" className={pathName === "/transcribetasks" ? "flex items-center" : "hover:underline flex items-center"}>
              <svg className="w-[30px] h-[30px] text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                <path stroke="white" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 4h3a1 1 0 0 1 1 1v15a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h3m0 3h6m-3 5h3m-6 0h.01M12 16h3m-6 0h.01M10 3v4h4V3h-4Z" />
              </svg>
              <p className={pathName === "/review-transcript" ? "font-bold underline text-[24px] pl-4" : "text-[20px] pl-4"}>Review Transcript</p>              </Link>
          </li>
          <li className="font-light text-sm text-white">
            <Link to="/summarize" className={pathName === "/summarize" ? "flex items-center" : "hover:underline flex items-center"}>
              <svg className="w-[30px] h-[30px] text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                <path stroke="white" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 9h3m-3 3h3m-3 3h3m-6 1c-.306-.613-.933-1-1.618-1H7.618c-.685 0-1.312.387-1.618 1M4 5h16a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1Zm7 5a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z" />
              </svg>
              <p className={pathName === "/summarize" ? "font-bold text-[24px] underline pl-4" : "text-[20px] pl-4"}>All Jobs</p>              </Link>
          </li>
          <li className="font-light text-sm text-white">
            <Link to="/templates" className={pathName === "/templates" ? "flex items-center" : "hover:underline flex items-center"}>
              <svg className="w-[30px] h-[30px] text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                <path stroke="white" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7h1v12a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V5a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h11.5M7 14h6m-6 3h6m0-10h.5m-.5 3h.5M7 7h3v3H7V7Z" />
              </svg>
              <p className={pathName === "/templates" ? "font-bold text-[24px] underline pl-4" : "text-[20px] pl-4"}>Templates</p>              </Link>
          </li>
          <li className="font-light text-sm text-white">
            <Link to="/coding" className={pathName === "/coding" ? "flex items-center" : "hover:underline flex items-center"}>
              <svg className="w-[30px] h-[30px] text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                <path stroke="white" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 3v4a1 1 0 0 1-1 1H5m5 4-2 2 2 2m4-4 2 2-2 2m5-12v16a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V7.914a1 1 0 0 1 .293-.707l3.914-3.914A1 1 0 0 1 9.914 3H18a1 1 0 0 1 1 1Z" />
              </svg>
              <p className={pathName === "/coding" ? "font-bold text-[24px] underline pl-4" : "text-[20px] pl-4"}>Coding</p>              </Link>
          </li>
          <li className="font-light text-sm text-white">
            <Link to="/register-doctors" className={pathName === "/register-doctors" ? "flex items-center" : "hover:underline flex items-center"}>
              <svg className="w-[30px] h-[30px] text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                <path stroke="white" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 12h4m-2 2v-4M4 18v-1a3 3 0 0 1 3-3h4a3 3 0 0 1 3 3v1a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1Zm8-10a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
              </svg>
              <p className={pathName === "/register-doctors" ? "font-bold text-[24px] underline pl-4" : "text-[20px] pl-4"}>Register Doctors</p>              </Link>
          </li>
          <li className="font-light text-sm text-white">
            <Link to="/update-doctors" className={pathName === "/update-doctors" ? "flex items-center" : "hover:underline flex items-center"}>
              <svg className="w-[30px] h-[30px] text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                <path stroke="white" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 19H5a1 1 0 0 1-1-1v-1a3 3 0 0 1 3-3h1m4-6a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm7.441 1.559a1.907 1.907 0 0 1 0 2.698l-6.069 6.069L10 19l.674-3.372 6.07-6.07a1.907 1.907 0 0 1 2.697 0Z" />
              </svg>
              <p className={pathName === "/update-doctors" ? "font-bold text-[24px] underline pl-4" : "text-[20px] pl-4"}>Update Doctors</p>              </Link>
          </li>
        </ul>
      </div>
      <div className="flex justify-center items-center grow">
        <button className="text-white font-medium rounded-lg pt-4 pb-4 p-24 hover:bg-ktx-navy bg-dark-purple text-[20px]" onClick={() => logoutUser()}>
          Logout
        </button>
      </div>
    </div>
  );
}

export default Sidebar;
