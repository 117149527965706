import { useState } from "react";
import HuggingFace from "./HuggingFace";
import FileUpload from "./FileUpload";

export default function UploadDatasets() {
  const [uploadType, setUploadType] = useState(true);

  const handleUploadType = () => {
    setUploadType(!uploadType);
  }

  return (
    <div className="flex flex-col items-center">
      <h2 className="font-bold text-[23px] text-ktx-purple my-[2rem]">
        Upload Datasets
      </h2>
      {uploadType ? <HuggingFace updateType={handleUploadType} /> : <FileUpload updateType={handleUploadType}/>}
    </div>
  )
}