import RegistrationSideBar from "./RegistrationSideBar";
import RegisterForm from "./RegisterForm";

export default function RegisterDoctors({ setAuth }) {
  return (
    <div className="flex">
      <RegistrationSideBar />
      <RegisterForm setAuth={setAuth} />
    </div>
  );
};
