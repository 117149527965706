import { useState, useEffect } from "react";
import Sidebar from "../Sidebar";
import { queryAllDoctors } from "../../utils/functions";
import DoctorFinder from "./DoctorFinder";

export default function UpdateDoctors({ setAuth }) {
  const [doctors, setDoctors] = useState(null);
  const [doc, setDoc] = useState("");

  useEffect(() => {
    (async () => {
      setDoctors(await queryAllDoctors());
    })()
  }, [doc])

  return (
    <div className="flex">
      <div className="h-[100dvh]">
        <div className="flex h-[100%] fixed top-0">
          <Sidebar setAuth={setAuth} />
        </div>
      </div>
      <div className="ml-[369px] flex overflow-x-hidden">
        <div className="flex flex-col">
          <div className="flex flex-col justify-center items-center w-[78dvw]">
            <h1 className="font-bold text-[28px] text-ktx-purple my-[2rem]">
              Update Doctors
            </h1>
          </div>
          {doctors !== null ? <DoctorFinder doctors={doctors} doc={doc} setDoc={setDoc}/> : <div className="w-6 h-6 border-2 border-dashed rounded-full animate-spin border-ktx-purple mx-auto"></div>} 
        </div>
      </div>
    </div>
  )
}