const mytext = `DATE OF VISIT: 
PATIENT:  
DOB: 

REFERRING PHYSICIAN: 


PATIENT IDENTIFICATION:

REASON FOR EVALUATION: 

IMPRESSION: 
---------------


PLAN:
-------

COORDINATION OF CARE:
-----------------------------

DISCUSSION:
---------------
99205/99215
This is an illness that poses a threat to the patient’s life or a bodily function. The recommended
radiation therapy will require intensive weekly monitoring for toxicity.
99204/99214
This is a chronic illness with exacerbation/progression in the form of
The recommended radiation therapy will require intensive weekly monitoring for toxicity.
99213/99203
This is a stable chronic illness. There is a lower risk of morbidity from additional testing or
treatment at this point.

HISTORY OF PRESENT ILLNESS: 
-----------------------------------

PATHOLOGY:
---------------

IMAGING:
-----------

PAST CANCER THERAPY HISTORY:
1) No prior radiation therapy
2) No prior chemotherapy
3) No prior endocrine therapy
See HPI for details of prior therapies

PRIOR RADIATION:
---------------------
None

PACEMAKER? No

POTENTIALLY PREGNANT? No

PAST MEDICAL HISTORY:
----------------------------
1)

PAST SURGICAL HISTORY:
-----------------------------
1) 

NOTABLE PAST GYN HISTORY:
----------------------------------


MEDICATIONS:
----------------
1) 

ALLERGIES:
-------------

FAMILY HISTORY: 
-------------------
1)

SOCIAL HISTORY: 
--------------------
1) Marital Status: 
2) Children: 
3) Family/Friend Support: 
4) Accompanying patient today: 
5) Special Cultural/Religious needs:
6) With whom do we have permission to speak with regarding your illness?: 
7) Living situation: 
8) Occupation:  
9) Do you have an advanced directive?:

HABITS:
---------
1) Smoking: 
2) ETOH: 
3) Illicit drug use:

REVIEW OF SYSTEMS:
------------------------

PRIOR RADIATION:
---------------------
Date:
Dose:
Fractions:
Anatomic Site:
Practice Location/Physician:

OBJECTIVE:
-------------
Vitals:
- Weight: 
- Blood Pressure: 
- Heart Rate: 
- Oxygen Saturation: 
- Fatigue: 
- Pain: 

Physical Exam: 
- Eyes are normal and non-anicteric. Pupils react normally
- Mouth is not dry, tongue is midline.
- No adenopathy in neck.
- No tenderness in right collarbone. 
- Tenderness in left scapula and left superior ribs 1/2.
- No tenderness in spine or abdomen.
- 2/5 grip strength left, 5/5 grip strength right hands.
- weakness in left fingers. 
- Numbness in left hand since fall 2022.
- No swelling in the ankles.

LABS:
-------


Patient has given prior verbal consent to have the conversation recorded and summarized by the Knowtex software.`;

export default mytext;
