import { useState, useEffect } from 'react';
import { generateDateRange, queryWeeklyNotesLeaderboardForDoctors } from '../../../utils/functions';
import WeeklyLineGraph from './WeeklyLineGraph';

export default function WeeklyLeaderboard({ doctorsArray, org, setShowLeaderboard }) {
  const [weeksObject, setWeeksObject] = useState({});
  const [leaderboardData, setLeaderboardData] = useState(null);
  const [weekNumber, setWeekNumber] = useState(1);
  const [dateDisplay, setDateDisplay] = useState('');
  const [lineGraphData, setLinegraphData] = useState([]);
  const [showGraph, setShowGraph] = useState(false);

  useEffect(() => {
    setDateDisplay(generateDateRange(weekNumber));

    (async () => {
      if (!weeksObject[weekNumber]) {
        const data = await queryWeeklyNotesLeaderboardForDoctors(doctorsArray.map(el => ({ userName: el.user_name.split(" ").join(""), userEmail: el.user_email })), weekNumber, org);
        weeksObject[weekNumber] = data;
        setWeeksObject({ ...weeksObject })

        const newLineGraphData = [...lineGraphData, data.currentWeekGraphData];

        setLinegraphData(newLineGraphData);
        setLeaderboardData(weeksObject[weekNumber].currentWeekLeaderboard);
      } else {
        setLeaderboardData(weeksObject[weekNumber].currentWeekLeaderboard);
      }
    })();

  }, [weekNumber]);

  const handleWeekNavigation = (e) => {
    if (e.target.id === "next") {
      setWeekNumber(weekNumber + 1);
    } else if (e.target.id === "prev") {
      setWeekNumber(weekNumber - 1);
    }
  }

  const handleOpenGraph = () => {
    setShowGraph(true);
  }

  return (
    <>
      {leaderboardData !== null ? (
        <div className="fixed bg-barely-purple w-[60%] max-h-[500px] flex flex-col rounded-md shadow-knowtex-shadow">
          <h2 className="text-[26px] font-semibold text-center bg-light-purple rounded-t-md py-2 mb-2">Leaderboard: {org}</h2>
          <h3 className="text-[18px] font-semibold text-center bg-barely-purple">Clinician Activation: {leaderboardData.length}/{doctorsArray.length}</h3>
          {Object.keys(weeksObject).length > 0 &&
            <select className="w-[50%] mx-auto rounded-md" value={weekNumber} onChange={(e) => setWeekNumber(parseInt(e.target.value))}>
              {Object.keys(weeksObject).map((el, i) => (
                <option key={i} value={el}>Week of {generateDateRange(el)}</option>
              ))}
            </select>
          }
          <button id={"next"} className="text-ktx-purple text-[30px] font-semibold hover:text-ktx-navy  absolute top-[7.7rem] z-20 left-2" onClick={handleWeekNavigation}>{"<"}</button>
          <button className="text-ktx-purple text-[20px] font-semibold hover:text-ktx-navy  absolute top-[5.8rem] z-20 right-[9%]" onClick={handleOpenGraph}>Show Graph</button>
          <button id={"prev"} className="text-ktx-purple text-[30px] font-semibold hover:text-ktx-navy  absolute top-[7.7rem] z-20 right-2" disabled={weekNumber === 1} onClick={handleWeekNavigation}>{">"}</button>
          <button className="w-[50%] mx-auto text-ktx-purple hover:text-ktx-navy my-2" onClick={() => setShowLeaderboard("monthly")}>Show Monthly Leaderboard</button>
          <button className="absolute top-[-5px] right-[5px] text-[26px] p-2 z-10" onClick={() => setShowLeaderboard("")}>×</button>
          <div className="overflow-y-auto flex-1 rounded-t-md my-2">
            <table className="w-full">
              <thead className="sticky top-0 bg-light-purple">
                <tr className="text-left">
                  <th className="py-2 px-4">Rank</th>
                  <th className="py-2 px-4">Doctor</th>
                  <th className="py-2 px-4">Email</th>
                  <th className="py-2 px-4">Notes</th>
                </tr>
              </thead>
              <tbody>
                {leaderboardData.map((row, index) => (
                  <tr key={index} className="border-b">
                    <td className="py-2 px-4">{index + 1}</td>
                    <td className="py-2 px-4">{row.doctorName.replace(".", ". ")}</td>
                    <td className="py-2 px-4">{row.doctorEmail}</td>
                    <td className="py-2 px-4">{row.notes}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {showGraph && <WeeklyLineGraph lineGraphData={lineGraphData} setShowGraph={setShowGraph} />}
        </div>
      ) : (
        <div className="fixed bg-barely-purple w-[60%] max-h-[500px] flex flex-col rounded-md shadow-knowtex-shadow">
          <h4 className="text-[20px] font-semibold text-center bg-light-purple rounded-t-md">Week of {dateDisplay}</h4>
          <h2 className="text-[26px] font-semibold text-center bg-light-purple py-4">Leaderboard: {org}</h2>
          <button className="text-ktx-purple hover:text-ktx-navy bg-light-purple" onClick={() => setShowLeaderboard("monthly")}>Show Monthly Leaderboard</button>
          <button className="absolute top-[-5px] right-[5px] text-[26px] p-2 z-10" onClick={() => setShowLeaderboard("")}>×</button>
          <span className="w-6 h-6 border-2 border-dashed rounded-full animate-spin border-ktx-purple mx-auto m-4"></span>
        </div>
      )}
    </>
  );
}