/* This code snippet is a React component called `Player` that represents a media player interface.
Here's a breakdown of what the code is doing: */
import React, { useEffect, useRef, useState } from "react";
import "./player.scss";
import { BsFillPlayCircleFill, BsFillPauseCircleFill } from "react-icons/bs";

const Player = ({ isplaying, setisplaying, currTime, duration, audioElem }) => {
  const [playbackSpeed, setPlaybackSpeed] = useState(1);

  const clickRef = useRef();
  
  const PlayPause = () => {
    setisplaying(!isplaying);
  };

  // Function to handle key press
  const handleKeyPress = (event) => {
    if (event.key === "Escape") {
      event.preventDefault();
      PlayPause();
    }
  };

  // Set up the event listener for key press
  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);

    // Clean up the event listener
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [isplaying]);

  const skipAhead5 = () => {
    const newTime = audioElem.current.currentTime + 5;
    if (newTime < duration) {
      audioElem.current.currentTime = newTime;
    } else {
      audioElem.current.currentTime = duration;
    }
  };
  const skipAhead10 = () => {
    const newTime = audioElem.current.currentTime + 10;
    if (newTime < duration) {
      audioElem.current.currentTime = newTime;
    } else {
      audioElem.current.currentTime = duration;
    }
  };

  const skipBack5 = () => {
    const newTime = audioElem.current.currentTime - 5;
    if (newTime < duration) {
      audioElem.current.currentTime = newTime;
    } else {
      audioElem.current.currentTime = duration;
    }
  };

  const handleSpeedChange = (e) => {
    setPlaybackSpeed(Number(e.target.value));
    audioElem.current.playbackRate = Number(e.target.value);
  };

  const checkWidth = (e) => {
    let width = clickRef.current.clientWidth;
    const offset = e.nativeEvent.offsetX;

    const divprogress = (offset / width) * 100;
    audioElem.current.currentTime = (divprogress / 100) * duration;
  };

  const calculateTime = (secs) => {
    if (!secs) {
      return "00:00";
    }
    const minutes = Math.floor(secs / 60);
    const returnedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const seconds = Math.floor(secs) % 60;
    const returnedSecs = seconds < 10 ? `0${seconds}` : `${seconds}`;
    return returnedMinutes + ":" + returnedSecs;
  };

  return (
    <div className="player_container">
        <div className="text-ktx-purple">
          {calculateTime(currTime)} / {calculateTime(duration)}
        </div>
      <div className="navigation mb-1">
        <div className="navigation_wrapper mb-1" onClick={checkWidth} ref={clickRef}>
          <div
            className="seek_bar"
            style={{ width: `${(currTime / duration) * 100 + "%"}` }}
          ></div>
        </div>
        <div className="flex justify-evenly relative w-[100%]">
          <button className="text-3xl absolute left-0 bottom-0 px-5 text-ktx-purple hover:text-ktx-navy" onClick={skipBack5}>-5</button>
          <div className="controls">
            {isplaying ? (
              <BsFillPauseCircleFill
                className="text-4xl cursor-pointer text-ktx-purple hover:text-ktx-navy"
                onClick={PlayPause}
              />
            ) : (
              <BsFillPlayCircleFill className="text-4xl cursor-pointer text-ktx-purple hover:text-ktx-navy" onClick={PlayPause} />
            )}
          <button className="text-3xl absolute right-0 bottom-0 px-5 text-ktx-purple hover:text-ktx-navy" onClick={skipAhead5}>+5</button>
        </div>
      </div>
      </div>
      <div className="flex items-center">
          <select className="rounded-md border-none font-semibold text-ktx-purple" value={playbackSpeed} onChange={handleSpeedChange}>
            <option value={0.25}>0.25x</option>
            <option value={0.5}>0.5x</option>
            <option value={0.75}>0.75x</option>
            <option value={1}>1x</option>
            <option value={1.5}>1.5x</option>
            <option value={2}>2x</option>
          </select>
      </div>
    </div>
  );
};

export default Player;
