import { useState, useEffect } from "react";
import { queryNoteStatusForDoctor, queryWeeklyNoteStatusForDoctor } from "../../../utils/functions";
import TotalStatus from "./TotalStatus";
import WeeklyStatus from "./WeeklyStatus";

export default function NoteStatus({ doctor_id, access_token, doctor_name }) {
  const [noteStatus, setNoteStatus] = useState(null);
  const [weeklyNoteStatus, setWeeklyNoteStatus] = useState(null);

  const [openTotalModal, setOpenTotalModal] = useState(false);
  const [openWeeklyModal, setOpenWeeklyModal] = useState(false);

  useEffect(() => {
    (async () => {
      setNoteStatus(await queryNoteStatusForDoctor(doctor_id))
      setWeeklyNoteStatus(await queryWeeklyNoteStatusForDoctor(doctor_id))
    })()
  }, [])

  return (
    <>
      {noteStatus !== null ? (
        <>
          <button onClick={() => setOpenWeeklyModal(true)}>View</button>
          {openTotalModal && <TotalStatus noteStatus={noteStatus} setOpenTotalModal={setOpenTotalModal} openTotalModal={openTotalModal} setOpenWeeklyModal={setOpenWeeklyModal} openWeeklyModal={openWeeklyModal} access_token={access_token} doctor_name={doctor_name} />}
          {openWeeklyModal && <WeeklyStatus weeklyNoteStatus={weeklyNoteStatus} setOpenTotalModal={setOpenTotalModal} openWeeklyModal={openWeeklyModal} setOpenWeeklyModal={setOpenWeeklyModal} access_token={access_token} doctor_name={doctor_name} openTotalModal={openTotalModal} />}
        </>
      ) : (
        <span className="w-6 h-6 border-2 border-dashed rounded-full animate-spin border-ktx-purple mx-auto"></span>
      )
      }
    </>
  )
}