import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import { useState, useEffect } from 'react';

const COLORS = ["#783BFF", "#7FB1BA", "#01085A"];

export default function WeeklyStatus({ weeklyNoteStatus, setOpenTotalModal, setOpenWeeklyModal, openWeeklyModal, openTotalModal, doctor_name, access_token }) {
  const [pieData, setPieData] = useState(null);
  const [total, setTotal] = useState(null);

  useEffect(() => {
    setTotal(weeklyNoteStatus.total);

    if (weeklyNoteStatus !== null) {
      if (access_token) {
        setPieData(
          [
            { name: "Ready to Record", value: weeklyNoteStatus.record },
            { name: "Review", value: weeklyNoteStatus.review },
            { name: "Locked", value: weeklyNoteStatus.locked }
          ]
        )
      } else {
        setPieData(
          [
            { name: "Ready to Record", value: weeklyNoteStatus.record },
            { name: "Review", value: weeklyNoteStatus.review }
          ]
        )
      }
    }

  }, [openWeeklyModal, openTotalModal])

  const customLegend = (value, entry) => {
    const { value: chartVal } = entry.payload;

    if (total !== 0) {
      return `${value}: ${chartVal} - ${((chartVal / total) * 100).toFixed(2)}%`;
    } else {
      return `${value}: ${chartVal}`;
    }
  }

  const handleTotalClick = () => {
    setOpenTotalModal(true);
    setOpenWeeklyModal(false);
  }

  const closeWeeklyModal = () => {
    setOpenWeeklyModal(false);
  }

  return (
    <div className='fixed left-[28%] top-[28%] w-[50%] flex flex-col items-center justify-center bg-light-purple p-4 rounded-md shadow-md z-10'>
      <button onClick={handleTotalClick}>Show Historical</button>
      <h2 className='text-[26px]'>Weekly Note Status for {doctor_name}</h2>
      <h3 className='text-[22px]'>Integrated?: {access_token ? "Yes" : "No"}</h3>
      <button className='absolute right-[20px] top-[10px] hover:text-ktx-navy font-bold text-[26px]' onClick={closeWeeklyModal}>×</button>

      {total !== 0 ? (<div className='relative w-full h-full z-10'>
        <ResponsiveContainer width={"96%"} height={400}>
          <PieChart>
            <Pie
              data={pieData}
              cx={"42%"}
              cy={"40%"}
              labelLine={false}
              outerRadius={160}
              fill="#8884d8"
              dataKey="value"
            >
              {pieData !== null && pieData.map((_, i) => (
                <Cell key={`cell-${i}`} fill={COLORS[i % COLORS.length]} />
              ))}
            </Pie>
            <Tooltip />
            <Legend layout="vertical" align="right" iconType="circle" iconSize={10} verticalAlign='middle' formatter={customLegend} />
          </PieChart>
        </ResponsiveContainer>
      </div> ) : <div>No Notes This Week</div>}
      
    </div>
  )
}