import React, { useRef, useState, useEffect } from "react";
import Player from "../Player/Player";
import Sidebar from "../Sidebar";
import { formatChartData } from "../../utils/functions";

const Transcribe = ({ setAuth }) => {
  const [isplaying, setisplaying] = useState(false);
  const [currentAudio, setCurrentAudio] = useState("");
  const [jobName, setJobName] = useState("");
  const [OriginalTranscript, setOriginalTranscript] = useState("");
  const [patientsChange, setPatientsChange] = useState(false);
  const [patient, setPatient] = useState("");
  const [note, setNote] = useState("");
  const [patientName, setPatientName] = useState("");
  const [mrn, setMrn] = useState("");
  const [status, setStatus] = useState("");
  const [chartHistory, setChartHistory] = useState("");
  const [refresh, setRefresh] = useState(false);

  const [isStatus, setIsStatus] = useState(false);
  const [duration, setDuration] = useState("");
  const [currTime, setCurrTime] = useState("");
  const [selectedSentence, setSelectedSentence] = useState([]);

  const audioElem = useRef();

  useEffect(() => {
    setIsStatus(false);
  }, []);

  //submits updated note to Postgres DB
  const editText = async (id) => {
    try {
      const status = "Review";
      const body = { note, status };

      const myHeaders = new Headers();

      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("token", localStorage.token);

      await fetch(`${process.env.REACT_APP_API_URL}/reviews/${id}`, {
        method: "PUT",
        headers: myHeaders,
        body: JSON.stringify(body),
      });
      setPatientsChange(true);
      setIsStatus(true);

      //window.location = "/";
    } catch (err) {
      console.error(err.message);
    }

    // Now update the dynamodb
    updateDynamodbJobStatus();
  };

  //saves updated note to Postgres DB BUT DOES NOT CHANGE STATUS
  const saveText = async (id) => {
    try {
      const body = { note };

      const myHeaders = new Headers();

      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("token", localStorage.token);

      await fetch(`${process.env.REACT_APP_API_URL}/reviews/save/${id}`, {
        method: "PUT",
        headers: myHeaders,
        body: JSON.stringify(body),
      });
      setPatientsChange(true);

      //window.location = "/";
    } catch (err) {
      console.error(err.message);
    }
  };

  //retrieves SOAP note from Postgres DB
  async function getNote(id) {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/reviews/patient/${id}`,
        {
          method: "GET",
        }
      );

      const parseRes = await response.json();

      setNote(parseRes[0].note);
      setPatientName(parseRes[0].description);
      setMrn(parseRes[0].mrn);
      setStatus(parseRes[0].status);

      if (parseRes[0].chart_history) {
        setChartHistory(formatChartData(parseRes[0].chart_history));
      }
    } catch (err) {
      console.error(err.message);
    }
  }

  // Update the job name status scribed status to true
  async function updateDynamodbJobStatus() {
    var getTask_url = `${process.env.REACT_APP_DYNAMO_API_URL}/Prod/updateSummaryJob?summarize_job_name=${jobName}&is_reviewed=true`;
    postData(getTask_url).then((data) => {
      //console.log("Response of setting job name:%s to true :%v",jobName,data);
    });
  }

  //console.log("current audio: %v \n",currentAudio);

  async function postData(url = "", data = {}, method = "GET") {
    // Default options are marked with *
    const response = await fetch(url, {
      method: method, // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      // Add the 'token' as a header to allow access to backend

      headers: {
        token: window.localStorage.getItem("token"),
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      //body: JSON.stringify(data) // body data type must match "Content-Type" header
    });
    return response.json(); // parses JSON response into native JavaScript objects
  }

  const handleJobChange = (event) => {
    setJobName(event.target.value);
  };

  // function to fetch the job info given by the given job name
  const fetchJobInfo = (event) => {
    var getTask_url = `${process.env.REACT_APP_DYNAMO_API_URL}/Prod/job?transcribe_job_name=${jobName}`;
    postData(getTask_url).then((data) => {
      //console.log(getTask_url);
      // console.log("Got response:%v", data);

      // set the original task info
      // setTasks(data);
      // // set the reviewed words by the original words
      // setReviewedWords(data.original_words);

      // update the url
      https: setCurrentAudio({ title: data.jobName, url: data.audio_url });
      // update the original transcript
      setOriginalTranscript(data.original_transcript_text);
      //set ai created note
      setPatient(data.patient_id);
      
      getNote(data.patient_id);
    });
  };

  useEffect(() => {
    //console.log("Current playing time :%v, duration:%v \n",audioElem.current.currentTime,audioElem.current.duration);
    if (isplaying) {
      audioElem.current.play();
    } else {
      audioElem.current.pause();
    }
  }, [isplaying]);

  const onPlaying = () => {
    setDuration(audioElem.current.duration);
    setCurrTime(audioElem.current.currentTime);
    //console.log("Current audio info:%v \n", currTime);
    // console.log("duration:%v \n", duration);
  };

  const updateNoteStatus = async (id) => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/reviews/patient/${id}`,
        {
          method: "GET",
        });

      const parseRes = await res.json();
      setStatus(parseRes[0].status);
    } catch (err) {
      console.error(err.message);
    }
  }
  
  useEffect(() => {
    if (jobName && patient) {
      const interval = setInterval(async () => {

        try {
          updateNoteStatus(patient);
          setRefresh(!refresh);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }, 5000);

      return () => clearInterval(interval);
    }
  }, [jobName, refresh]);

  return (

    <div className="flex">
      <div className="h-[100dvh]">
        <div className="flex h-[100%] fixed top-0">
          <Sidebar setAuth={setAuth} />
        </div>
      </div>
      <div className="ml-[369px] flex overflow-x-hidden">
        <div className="flex flex-col">
          <div className="flex flex-col justify-center items-center gap-[2rem] w-[78dvw]">
            <h1 className="font-bold text-[28px] text-ktx-purple mt-[3rem]">Review Transcript</h1>
            <div className="flex w-[85%]">
              <div className="flex rounded-md bg-white shadow-knowtex-shadow w-[80%] h-[2.5rem]">
                <svg className="self-center ml-[.4rem] w-6 h-6 text-ktx-navy dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 7H5a2 2 0 0 0-2 2v4m5-6h8M8 7V5a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2m0 0h3a2 2 0 0 1 2 2v4m0 0v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-6m18 0s-4 2-9 2-9-2-9-2m9-2h.01" />
                </svg>
                <input
                  type="job"
                  placeholder="Job"
                  name="job"
                  required={true}
                  className="text-ktx-navy p-[.5rem] w-[100%] italic border-none bg-none rounded-md self-center"
                  value={jobName}
                  onChange={handleJobChange}
                />
              </div>
              <button className="disabled:bg-transparent-grey font-semibold hover:bg-ktx-navy rounded-md bg-ktx-purple ml-[1.7rem] tracking-widest text-white py-[.7rem] h-[2.5rem] px-[3rem] w-[20%] items-center flex justify-center"
                onClick={fetchJobInfo}
                disabled={!jobName.startsWith("PROD")}>Get Job</button>
            </div>
            <div className="flex w-[85%] justify-evenly gap-x-[1.25rem]">
              <div className="flex rounded-md bg-white  w-[25%] h-[2.5rem]">
                <svg
                  className="self-center ml-[.4rem] w-6 h-6 text-ktx-navy dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M15 9h3m-3 3h3m-3 3h3m-6 1c-.306-.613-.933-1-1.618-1H7.618c-.685 0-1.312.387-1.618 1M4 5h16a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1Zm7 5a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z"
                  />
                </svg>
                <a
                  type="text"
                  className="text-ktx-navy w-[100%] border-none bg-none self-center rounded-md"
                >
                  Patient: {patientName}
                </a>
              </div>
              <div className="flex rounded-md bg-white w-[25%] h-[2.5rem]">
                <svg className="self-center ml-[.4rem] w-6 h-6 text-ktx-navy dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 6c0 1.657-3.134 3-7 3S5 7.657 5 6m14 0c0-1.657-3.134-3-7-3S5 4.343 5 6m14 0v6M5 6v6m0 0c0 1.657 3.134 3 7 3s7-1.343 7-3M5 12v6c0 1.657 3.134 3 7 3s7-1.343 7-3v-6" />
                </svg>

                <a
                  type="text"
                  className="text-ktx-navy w-[100%] border-none bg-none self-center rounded-md"
                >
                  MRN: {mrn}
                </a>
              </div>
              {/* <div className="flex rounded-md bg-white  w-[25%] h-[2.5rem]">
                <svg
                  className="self-center ml-[.4rem] w-6 h-6 text-ktx-navy dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    strokeWidth="2"
                    d="M7 17v1a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1a3 3 0 0 0-3-3h-4a3 3 0 0 0-3 3Zm8-9a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                  />
                </svg>
                <a
                  type="text"
                  className="text-ktx-navy w-[100%] border-none bg-none self-center rounded-md"
                >
                  Reviewer: {reviewerId}
                </a>
              </div> */}
              <div className="flex rounded-md bg-white  w-[25%] h-[2.5rem]">
                <svg
                  className="self-center ml-[.4rem] w-6 h-6 text-ktx-navy dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 12a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1M5 12h14M5 12a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1m-2 3h.01M14 15h.01M17 9h.01M14 9h.01"
                  />
                </svg>
                <a
                  type="text"
                  className="text-ktx-navy w-[100%] border-none bg-none self-center rounded-md"
                >
                  Status: {status}
                </a>
              </div>
              {/* <div className="flex rounded-md bg-white w-[25%] h-[2.5rem]">
                <svg
                  className="self-center ml-[.4rem] w-6 h-6 text-ktx-navy dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 8h6m-6 4h6m-6 4h6M6 3v18l2-2 2 2 2-2 2 2 2-2 2 2V3l-2 2-2-2-2 2-2-2-2 2-2-2Z"
                  />
                </svg>
                <a
                  type="text"
                  className="text-ktx-navy w-[100%] border-none bg-none self-center rounded-md"
                >
                  Did Review: {String(doctorDidReview)}
                </a>
              </div> */}
            </div>

            <div className="w-[100%] items-center ml-[15%]">
              <audio
                src={currentAudio.url}
                ref={audioElem}
                onTimeUpdate={onPlaying}
              />
              <Player
                isplaying={isplaying}
                setisplaying={setisplaying}
                audioElem={audioElem}
                currentAudio={currentAudio}
                setCurrentAudio={setCurrentAudio}
                duration={duration}
                currTime={currTime}
              />
            </div>


          </div>

          {/* History of present illness and assessment */}

          <div className="flex flex-col justify-center items-center">
            <div className="flex mt-[2rem] w-[85%] shadow-knowtex-shadow rounded-md">
              <div className="flex flex-col w-[50%]">
                <h3 className="p-[.25rem] text-[1.25rem] text-white bg-dark-purple rounded-tl-md">Transcript</h3>
                <textarea id="transcript" defaultValue={OriginalTranscript}
                  className="border-none resize-none h-[20rem] py-[1rem] bg-barely-purple text-ktx-navy rounded-bl-md" />
              </div>
              <div className="flex flex-col w-[50%]">
                <h3 className="p-[.25rem] text-[1.25rem] text-white bg-dark-purple rounded-tr-md">Final Note</h3>
                <textarea id="note" value={note}
                  onChange={(e) => setNote(e.target.value)}
                  className="border-none resize-none h-[20rem] py-[1rem] bg-barely-purple text-ktx-navy rounded-br-md" />
              </div>
            </div>
          </div>

          <div className="flex flex-col justify-center items-center">
            <div className="flex mt-[1rem] w-[85%] shadow-knowtex-shadow rounded-md">
              <div className="flex flex-col w-full p-8]">
                <h3 className="p-[.25rem] text-[1.25rem] text-white bg-dark-purple rounded-t-md">
                  Chart History
                </h3>
                <textarea
                  id="chart-history"
                  defaultValue={!patient ? "" : chartHistory ? chartHistory : "No chart history available"}
                  className={!chartHistory ? `border-none resize-none h-[9rem] py-[1rem] bg-barely-purple text-ktx-navy rounded-bl-md` : `border-none resize-none h-[30rem] py-[1rem] bg-barely-purple text-ktx-navy rounded-bl-md`}
                />
              </div>
            </div>
          </div>

          <div className="my-[2rem] flex justify-end w-[92.5%] gap-[1.7rem]">
            <button
              className="hover:text-ktx-navy text-ktx-purple underline font-semibold"
              type="button"
              onClick={() => saveText(patient)}
            >
              Save Draft
            </button>

            <button type="button" onClick={() => editText(patient)}
              className="font-semibold hover:bg-ktx-navy rounded-md bg-ktx-purple text-white py-[.7rem] h-[2.5rem] px-[2rem] w-auto items-center flex justify-center tracking-widest"
            >
              Upload Note
            </button>

            {isStatus && <div>Note Uploaded</div>}
          </div>

        </div>
      </div>
    </div>
  );
};

export default Transcribe;
