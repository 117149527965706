import { useState, useEffect } from 'react';
import EmailListDisplay from './EmailListDisplay';
import CurrentMonthLeaderboard from './CurrentMonthLeaderboard';
import WeeklyLeaderboard from './WeeklyLeaderboard';

export default function OrgDetails({ getOrgData, org, setOpenOrgModal, doctorsArr }) {
  const [showPreviousEmails, setShowPreviousEmails] = useState(false);
  const [showCurrentEmails, setShowCurrentEmails] = useState(false);

  const [showPreviousNames, setShowPreviousNames] = useState(false);
  const [showCurrentNames, setShowCurrentNames] = useState(false);

  const [showLeaderboard, setShowLeaderboard] = useState("");

  const closeModal = () => {
    setOpenOrgModal(false);
  }

  return (
    <>
      {getOrgData !== null ? (
        <div className="fixed left-[28%] top-[28%] w-[50%] flex flex-col items-center justify-center bg-light-purple p-4 rounded-md shadow-md z-10">
          <button className='absolute right-[20px] top-[10px] hover:text-ktx-navy font-bold text-[26px]' onClick={() => closeModal()}>×</button>
          <h2 className="font-semibold text-[26px]">Organization Details: {org}</h2>
          <p>Total Notes: {getOrgData.totalCount}</p>
          <p>Historical Active Doctors: {getOrgData.totalActiveDoctors}</p>
          <p className="border-solid border-b-[2px] border-b-ktx-purple w-full text-center pb-2">Total Doctors: {getOrgData.doctorCount}</p>
          <h4 className="font-semibold text-[20px] my-2">Current and Previous Usage</h4>
          <div className='relative'>
            <p>Previous Month Doctors: {getOrgData.activeDoctorsPreviousMonth}</p>
            <button onClick={() => setShowPreviousEmails(!showPreviousEmails)} className="text-ktx-purple hover:text-ktx-navy absolute top-0 right-[-140px] ">Show Emails</button>
            <button onClick={() => setShowPreviousNames(!showPreviousNames)} className="text-ktx-purple hover:text-ktx-navy absolute top-0 left-[-140px] ">Show Names</button>
            {showPreviousEmails && <EmailListDisplay emailList={getOrgData.previousMonthUserEmails} setShowPreviousEmails={setShowPreviousEmails} componentName={"Emails-Prev"} />}
            {showPreviousNames && <EmailListDisplay emailList={getOrgData.previousMonthUserNames} setShowPreviousNames={setShowPreviousNames} componentName={"Names-Prev"} />}
          </div>
          <p>Previous Month Notes: {getOrgData.totalNotesPreviousMonth}</p>
          <div className='relative'>
            <p>Current Month Doctors: {getOrgData.activeDoctorsCurrentMonth}</p>
            <button onClick={() => setShowCurrentEmails(!showCurrentEmails)} className="text-ktx-purple hover:text-ktx-navy absolute top-0 right-[-140px]">Show Emails</button>
            <button onClick={() => setShowCurrentNames(!showCurrentNames)} className="text-ktx-purple hover:text-ktx-navy absolute top-0 left-[-140px]">Show Names</button>
            {showCurrentEmails && <EmailListDisplay emailList={getOrgData.currentMonthUserEmails} setShowCurrentEmails={setShowCurrentEmails} componentName={"Emails-Curr"} />}
            {showCurrentNames && <EmailListDisplay emailList={getOrgData.currentMonthUserNames} setShowCurrentNames={setShowCurrentNames} componentName={"Names-Curr"} />}
          </div>
          <p>Current Month Notes: {getOrgData.totalNotesCurrentMonth}</p>
          <button onClick={() => setShowLeaderboard("monthly")} className="text-ktx-purple hover:text-ktx-navy">Show Leaderboard</button>
          {showLeaderboard === 'monthly' && <CurrentMonthLeaderboard leaderboardData={getOrgData.currentMonthLeaderboard} org={org} setShowLeaderboard={setShowLeaderboard} totalDoctors={getOrgData.doctorCount} />}
          {showLeaderboard === "weekly" && <WeeklyLeaderboard org={org} doctorsArray={doctorsArr} showLeaderboard={showLeaderboard} setShowLeaderboard={setShowLeaderboard} totalDoctors={getOrgData.totalDoctors} />}
        </div>
      ) : (
        <div className="fixed left-[28%] top-[28%] w-[50%] flex flex-col items-center justify-center bg-light-purple p-4 rounded-md shadow-md z-10">
          <button className='absolute right-[20px] top-[10px] hover:text-ktx-navy font-bold text-[26px]' onClick={() => closeModal()}>×</button>
          <h2>Organization Details: {org}</h2>
          <span className="w-6 h-6 border-2 border-dashed rounded-full animate-spin border-ktx-purple mx-auto"></span>
        </div>
      )}
    </>
  )
}