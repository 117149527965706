export const queryAllDatasets = async () => {
  try {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/fine-tuning/datasets`);

    if (res.ok) {
      const data = await res.json();
      
      return data.datasets;
    } else {
      console.error("Error querying datasets:", res.statusText);
      alert("Error querying datasets");
    }
  } catch (error) {
    console.error("Error querying datasets:", error);
  }
}

export const queryDataset = async (datasetId) => {
  try {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/fine-tuning/datasets/${datasetId}`);

    if (res.ok) {
      const data = await res.json();
      
      return data;
    } else {
      console.error("Error querying dataset:", res.statusText);
      alert("Error querying dataset");
    }
  } catch (error) {
    console.error("Error querying dataset:", error);
  }
}

export const importHuggingFaceDataset = async (datasetName) => {
  try {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/fine-tuning/huggingface-import?huggingface_dataset=${datasetName}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
    });
    
    if (res.ok) {
      const data = await res.json();
      
      return data;
    } else {
      console.error("Error importing dataset:", res.statusText);
      alert("Error importing dataset");
    }
  } catch (error) {
    console.error("Error importing dataset:", error);
  }
}

export const uploadDataset = async (file) => {
  const formData = new FormData();
  formData.append("file", file);

  try {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/fine-tuning/file-upload`, {
      method: "POST",
      body: formData,
    });

    if (res.ok) {
      const data = await res.json();
      return data;
    } else {
      console.error("Error uploading dataset:", res.statusText);
      alert("Error uploading dataset");
    }
  } catch (error) {
    console.error("Error uploading dataset:", error);
  }
}


export const deleteDataset = async (datasetId) => {
  try {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/fine-tuning/datasets/${datasetId}`, {
      method: "DELETE"
    });

    if (res.ok) {
      const data = await res.json();

      return data;
    } else {
      console.error("Error deleting dataset:", res.statusText);
      alert("Error deleting dataset");
    }
  } catch (error) {
    console.error("Error deleting dataset:", error);
  }
}

export const queryAllModels = async () => {
  try {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/fine-tuning/models`);

    if (res.ok) {
      const data = await res.json();
      
      return data.models;
    } else {
      console.error("Error querying models:", res.statusText);
      alert("Error querying models");
    }
  } catch (error) {
    console.error("Error querying models:", error);
  }
}

export const createNewModel = async (modelData) => {
  try {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/fine-tuning/models`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(modelData)
    });

    if (res.ok) {
      const data = await res.json();
      
      return data;
    } else {
      console.error("Error creating model:", res.statusText);
      alert("Error creating model");
    }
  } catch (error) {
    console.error("Error creating model:", error);
  }
}

export const queryModel = async (modelId) => {
  try {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/fine-tuning/models/${modelId}`);

    if (res.ok) {
      const data = await res.json();
      
      return data;
    } else {
      console.error("Error querying model:", res.statusText);
      alert("Error querying model");
    }
  } catch (error) {
    console.error("Error querying model:", error);
  }
}

export const updateModelConfig = async (modelId, configData) => {
  try {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/fine-tuning/models/${modelId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(configData)
    });

    if (res.ok) {
      const data = await res.json();
      
      return data;
    } else {
      console.error("Error updating model config:", res.statusText);
      alert("Error updating model config");
    }
  } catch (error) {
    console.error("Error updating model config:", error);
  }
}

export const updateHuggingfaceModelName = async (modelId, huggingfaceModelName) => {
  try {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/fine-tuning/models/${modelId}/update`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ huggingfaceModelName })
    });

    if (res.ok) {
      const data = await res.json();
      
      return data;
    } else {
      console.error("Error updating huggingface model name:", res.statusText);
      alert("Error updating huggingface model name");
    }
  } catch (error) {
    console.error("Error updating huggingface model name:", error);
  }
}

export const fineTuneModel = async (modelId) => {
  try {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/fine-tuning/fine-tune/${modelId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      }
    });

    if (res.ok) {
      const data = await res.json();
      
      return data;
    } else {
      console.error("Error fine-tuning model:", res.statusText);
      alert("Error fine-tuning model");
    }
  } catch (error) {
    console.error("Error fine-tuning model:", error);
  }
}

export const deployModel = async (modelId) => {
  try {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/fine-tuning/models/${modelId}/deploy`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      }
    });

    if (res.ok) {
      const data = await res.json();
      alert("Model deployed successfully");
      return data;
    } else {
      console.error("Error deploying model:", res.statusText);
      alert("Error deploying model");
    }
  } catch (error) {
    console.error("Error deploying model:", error);
  }
}

export const terminateModel = async (modelId) => {
  try {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/fine-tuning/models/${modelId}/terminate`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      }
    });

    if (res.ok) {
      const data = await res.json();
      
      return data;
    } else {
      console.error("Error terminating model:", res.statusText);
      alert("Error terminating model");
    }
  } catch (error) {
    console.error("Error terminating model:", error);
  }
}