import { useState } from "react";

export default function EmailListDisplay({ emailList, nameList, setShowPreviousEmails, setShowPreviousNames, setShowCurrentEmails, setShowCurrentNames, componentName=null }) {
  const [copied, setCopied] = useState("");

  const copyToClipboard = async () => {
    navigator.clipboard.writeText(emailList.map(el => `\n${el}`));

    setCopied("Successfully Copied Email List!");

    setTimeout(() => {
      setCopied("");
    }, 1000)
  }

  return (
    <div className="bg-white fixed top-10 w-[60%] right-[20%] flex flex-col gap-4 p-[1rem] shadow-knowtex-shadow rounded-md">
      <button onClick={copyToClipboard} className="relative bg-ktx-purple text-white p-2 rounded-md w-20% mx-auto hover:bg-ktx-navy">{nameList === null ? "Copy Emails" : "Copy Names"}</button>
      {componentName === "Emails-Prev" && <button className="absolute top-2 right-4 text-[26px]" onClick={() => setShowPreviousEmails(false)}>×</button>}
      {componentName === "Emails-Curr" && <button className="absolute top-2 right-4 text-[26px]" onClick={() => setShowCurrentEmails(false)}>×</button>}
      {componentName === "Names-Prev" && <button className="absolute top-2 right-4 text-[26px]" onClick={() => setShowPreviousNames(false)}>×</button>}
      {componentName === "Names-Curr" && <button className="absolute top-2 right-4 text-[26px]" onClick={() => setShowCurrentNames(false)}>×</button>}
      {emailList && (
        <div>{emailList.map(el => `\n${el}`)}</div>
      )
      }
      {nameList && (
        <div>{nameList.map(el => `\n${el}`)}</div>
      )
      }
      {copied && (
        <p className="absolute bottom-">{copied}</p>
      )}
    </div>
  )
}