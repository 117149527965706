const mytext = `DATE OF VISIT:
PATIENT:
PATIENT NUMBER:
DOB:

REFERRING PROVIDER:
CHIEF COMPLAINT:

HISTORY OF PRESENT ILLNESS:
-----------------------------------

INTERVAL HISTORY:
----------------------

PAST MEDICAL HISTORY:
---------------------

ALLERGIES:
-------------
No known allergies
No known drug allergies

MEDICATIONS:
----------------

FAMILY HISTORY:
-------------------

TOBACCO USE AND CESSATION COUNSELING:
----------------------------------------------------
Never smoker
Cessation - Not applicable.

SOCIAL HISTORY:
-------------------
1) Marital Status:
2) Children:
3) Family/Friend Support:
4) Accompanying patient today:
5) Special Cultural/Religious needs:
6) With whom do we have permission to speak with regarding your illness?:
7) Living situation:
8) Occupation:
9) Do you have an advanced directive?:

GYN HISTORY:
-------------------
Age of menarche:
Pregnancies:
Estrogen Use:

REVIEW OF SYSTEMS:
------------------------
Patient Stated Pain Level: 0-1 None.
Constitutional Symptoms: Patient reports No fever. No rigors. No weight loss. No night sweats.
HEENT: Patient reports No blurred vision. No double vision. No hearing loss. No mouth sores.
Respiratory: Patient reports No difficulty breathing reported. No wheezing. No cough. No hemoptysis.
Cardiovascular: Patient reports No chest pain. No palpitations. No edema.
Gastrointestinal: Patient reports No nausea. No vomiting. No dysphagia. No heartburn. No abdominal pain. No diarrhea. No constipation. No blood in stool.
Genitourinary: Patient reports No hematuria. No dysuria. No urgency. No incontinence.
Musculoskeletal: Patient reports No bone pain. No myalgia. No back pain. No arthralgia. No joint swelling.
Integumentary: Patient reports No rash. No pruritis.
Neurological: Patient reports No headache. No paralysis. No paresthesias. No speech impairment.
Hematologic: Patient reports No excessive or spontaneous bleeding or bruising.
Mental Health: Patient reports No anxiety; no depression; no insomnia; no panic disorder.

OBJECTIVE:
-------------
Vitals:
Height =
Weight =
Temp =
Pulse =
Resp =
SystolicBP =
DiastolicBP =
Pulse Ox =

Performance Status:
ECOG 0: Fully active, able to carry on all pre­disease performance without restriction

Physical Examination:
General: Well developed, well nourished patient, in no acute distress. Patient appears stated age.
Skin: No abnormal skin lesions noted.
Head: Normocephalic.
Eyes: Sclerae are anicteric.
Ears, Nose, Throat, and Mouth: Normal oral mucosa and oropharynx.
Neck: Neck is supple. No cervical masses present. Trachea is midline. No thyromegaly.
Lungs: Clear to auscultation. No abnormal breath sounds or rubs.
Cardiac: normal rate; regular rhythm. S1/S2 without murmurs.
Abdomen:  Abdomen is soft, non­tender, non­distended, without masses. No hepatomegaly. No splenomegaly. No ascites present. Bowel sounds active.
Hematologic/Lymphatic: No petechiae. No purpura. No cervical LAN.
Extremities: No edema. No cyanosis. No digital clubbing. No discoloration.
Musculoskeletal: Normal range of motion. Strength and tone are normal. Back and Spine nontender to palpation and percussion.
Genitourinary: Deferred.
Rectal/Pelvic Exam: Deferred.
Neurologic: No focal findings on screening exam.

LABORATORY RESULTS:
--------------------------

IMAGING RESULTS:
---------------------

IMPRESSION:
--------------

PLAN:
------

DISCUSSION:
--------------
- The diagnosis and care plan were discussed with the patient in detail.
- I discussed the diagnosis, prognosis, risks and goals of therapy and answered all the patient’s questions  

Fax to:

Patient has given prior verbal consent to have the conversation recorded and summarized by the Knowtex software.`;

export default mytext;
