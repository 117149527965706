import { useState, useEffect } from "react";
import { updateDoctorFields, deleteDoctor, updatePassword } from "../../utils/functions";
import FirstDeleteModal from "./FirstDeleteModal";
import SecondDeleteModal from "./SecondDeleteModal";
import UpdatePasswordModal from "./UpdatePasswordModal";

export default function DoctorData({ doctorData, specialties, resetDocForm, orgs, setDoctorData, accountDeleted, setAccountDeleted }) {
  const username = localStorage.getItem('username');
  const userEmail = localStorage.getItem('userEmail');

  const [dynamicDoctorData, setDynamicDoctorData] = useState(doctorData);

  const [changesSaved, setChangesSaved] = useState(false);
  const [openPasswordModal, setOpenPasswordModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState("");
  const [passwordChanged, setPasswordChanged] = useState(false);

  const [password, setPassword] = useState("");

  useEffect(() => {
    setDynamicDoctorData(dynamicDoctorData);
  }, [dynamicDoctorData]);

  const handleInputChange = (e, field) => {
    let value = e.target.value;

    if (field === 'is_subscribed') {
      if (value === 'true') {
        value = true;
      } else if (value === 'false') {
        value = false;
      } else {
        value = null;
      }
    }

    setDynamicDoctorData(prevState => ({
      ...prevState,
      [field]: value
    }));
  };

  const handleResetDoctorData = () => {
    setDynamicDoctorData(doctorData);
  }

  const handleCommitDoctorData = async () => {
    dynamicDoctorData.username = username;

    try {
      setDoctorData(await updateDoctorFields(dynamicDoctorData));
      delete dynamicDoctorData.username;

      setChangesSaved(true);

      setTimeout(() => {
        setChangesSaved(false);
      }, 1500);

    } catch (err) {
      console.error(err);
    }

  }
  
  const handleOpenPasswordModal = () => {
    setOpenPasswordModal(true);
  }

  const handleClosePasswordModal = () => {
    setOpenPasswordModal(false);
  }

  const handleOpenFirstDeleteModal = () => {
    setOpenDeleteModal('first');
  }

  const handleOpenSecondDeleteModal = () => {
    setOpenDeleteModal('second');
  }

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal("");
  }

  const handleDeleteUser = async () => {
    await deleteDoctor(doctorData.user_id, username);

    resetDocForm();
    setOpenDeleteModal("");
    setAccountDeleted(true);

    setTimeout(() => {
      setAccountDeleted(false);
    }, 1500);
  }
  
  const handleSubmitPassword = async () => {
    dynamicDoctorData.username = username;
    dynamicDoctorData.password = password;
    dynamicDoctorData.userEmail = userEmail;

    try {
      await updatePassword({ ...dynamicDoctorData });

      setPasswordChanged(true);
      setPassword("");
      //removes appended fields from dynamicDoctorData
      delete dynamicDoctorData.username;
      delete dynamicDoctorData.password;
      delete dynamicDoctorData.userEmail;
      setOpenPasswordModal(false);

      setTimeout(() => {
        setPasswordChanged(false);
      }, 1500);
    } catch (err) {
      console.error(err);
    }
  }
  
  return (
    <>
      {dynamicDoctorData !== null ? (
        <div className="flex flex-col w-full gap-2 text-ktx-navy">
          <h3 className='mx-auto text-[22px] font-black text-ktx-purple'>{dynamicDoctorData.user_name}</h3>
          <table className='w-[90%] max-h-[400px] relative mb-4 mx-auto'>
            <button onClick={resetDocForm} className='absolute right-0 top-[-60px] rounded-md border-sage-green border-[1px] text-sage-green hover:bg-sage-green hover:text-white py-2 px-4 font-semibold'>New Doctor</button>
            <thead>
              <tr className='py-2 px-4'>
                <th className='border-b text-left'>Email</th>
                <th className='border-b'>Subscribed</th>
                <th className='border-b'>Specialty</th>
                <th className='border-b'>Organization</th>
                <th className='border-b px-2 text-center'>Password</th>
                <th className='border-b px-2 text-center'>Delete?</th>
              </tr>
            </thead>
            <tbody>
              <tr className='py-2 px-4 sticky' key={dynamicDoctorData.user_id}>
                <td className='relative p-2'><input type="email" className="rounded-md mx-auto max-w-[200px] p-2" required value={dynamicDoctorData.user_email} onChange={(e) => handleInputChange(e, 'user_email')} /></td>
                <td className='text-center p-2'>
                  <select
                    className="rounded-md mx-auto"
                    value={dynamicDoctorData.is_subscribed}
                    onChange={(e) => handleInputChange(e, 'is_subscribed')}
                  >
                    <option value={true}>Yes</option>
                    <option value={false}>No</option>
                    <option value={null}>Not Yet</option>
                  </select>
                </td>
                <td className='text-center p-2'>
                  <select
                    className="rounded-md mx-auto max-w-[200px]"
                    value={dynamicDoctorData.specialty}
                    onChange={(e) => handleInputChange(e, 'specialty')}
                  >
                    {specialties.map((el, i) => (
                      <option key={i} value={el.specialty}>{el.specialty}</option>
                    ))}
                  </select>
                </td>
                <td className='text-center p-2'>
                  <select
                    className="rounded-md mx-auto max-w-[200px]"
                    value={dynamicDoctorData.organization}
                    onChange={(e) => handleInputChange(e, 'organization')}
                  >
                    {orgs.map((el, i) => (
                      <option key={i} value={el.organization}>{el.organization}</option>
                    ))}
                  </select>
                </td>
                <td className='text-center p-2'>
                  <button onClick={handleOpenPasswordModal} className="rounded-md border-ktx-purple border-solid border-[2px] hover:bg-ktx-purple hover:text-white text-ktx-purple px-4 py-2">Reset Password</button>
                </td>
                <td className='text-center p-2'>
                  <button onClick={handleOpenFirstDeleteModal} className="rounded-md bg-ktx-red hover:bg-red-700 text-white py-2 px-4 font-semibold">
                    Delete
                  </button>
                </td>
              </tr>
            </tbody>
          </table>

          {JSON.stringify(dynamicDoctorData) !== JSON.stringify(doctorData) && (
            <div className="flex justify-center gap-8">
              <button onClick={handleResetDoctorData} className="bg-ktx-purple hover:bg-ktx-navy text-white p-4 w-[250px] rounded-md">Revert Changes</button>
              <button onClick={handleCommitDoctorData} className="bg-sage-green hover:bg-teal-700 text-white p-4 w-[250px] rounded-md">Commit Changes</button>
            </div>)}
          {changesSaved && <p className="text-ktx-purple text-center">Changes Saved!</p>}
          {accountDeleted && <p className="text-ktx-red text-center">Account Deleted!</p>}
          {passwordChanged && <p className="text-ktx-purple text-center">Password Changed!</p>}
          {openDeleteModal === 'first' && <FirstDeleteModal handleCloseDeleteModal={handleCloseDeleteModal} handleOpenSecondDeleteModal={handleOpenSecondDeleteModal} />}
          {openDeleteModal === 'second' && <SecondDeleteModal doctor_id={doctorData.user_id} handleDeleteUser={handleDeleteUser} handleCloseDeleteModal={handleCloseDeleteModal} />}
          {openPasswordModal && <UpdatePasswordModal setPassword={setPassword} password={password} handleClosePasswordModal={handleClosePasswordModal} handleSubmitPassword={handleSubmitPassword}/>}
        </div>
      ) : (
        <span className="w-6 h-6 border-2 border-dashed rounded-full animate-spin border-ktx-purple mx-auto"></span>
      )}
    </>
  );
}