import React, { useEffect, useState } from "react";

const TracePage = ({}) => {
  const [patientId, setPatientId] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior

    try {
      const body = { patient_id: patientId };
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/trace/fetch`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            token: localStorage.token,
          },
          body: JSON.stringify(body),
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();
      setResponseMessage(JSON.stringify(data, null, 2));
      setErrorMessage(""); // Clear any previous error
    } catch (error) {
      setErrorMessage(error.message);
      setResponseMessage(""); // Clear any previous success message
    }
  };

  return (
    <div className="flex flex-col min-w-[16rem] max-w-[32rem] mx-auto my-auto w-[50dvw]">
      <div className="flex flex-col">
        <div className="text-3xl text-light-grey tracking-wider mb-4">
          <div className="p-10">
            <div className="p-5 max-w-md mx-auto">
              <h2 className="text-2xl mb-4">Fetch Patient Trace</h2>
              <form onSubmit={handleSubmit}>
                <div className="mb-4">
                  <label
                    htmlFor="patientId"
                    className="block text-sm text-gray-700"
                  >
                    Patient ID:
                  </label>
                  <input
                    id="patientId"
                    value={patientId}
                    onChange={(e) => setPatientId(e.target.value)}
                    required
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
                <button
                  type="submit"
                  className="p-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  Submit
                </button>
              </form>

              {responseMessage && (
                <div className="mt-5 bg-green-50 p-4 rounded-md">
                  <h4 className="text-lg font-semibold text-green-800">
                    Response:
                  </h4>
                  <pre className="text-sm text-green-700 mt-2">
                    {responseMessage}
                  </pre>
                </div>
              )}

              {errorMessage && (
                <div className="mt-5 bg-red-50 p-4 rounded-md">
                  <h4 className="text-lg font-semibold text-red-800">Error:</h4>
                  <p className="text-sm text-red-700 mt-2">{errorMessage}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TracePage;
