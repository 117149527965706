import { useState, useEffect } from 'react';
import { queryProcessingNotesForDoctor } from '../../../utils/functions';

export default function ProcessingNotes({ doctor_id }) {
  const [processingNotes, setProcessingNotes] = useState(null);

  useEffect(() => {
    (async () => {
      setProcessingNotes(await queryProcessingNotesForDoctor(doctor_id));
    })()
  }, [])
  
  return (
    <>
      {processingNotes !== null ? (
        <p>
          {processingNotes}
        </p>
      ) : (
        <span className="w-6 h-6 border-2 border-dashed rounded-full animate-spin border-ktx-purple mx-auto"></span>
      )
      }
    </>
  )
}