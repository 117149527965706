import React, { useState, useEffect } from "react";
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import Pool from "../../UserPool";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { setLoginUser } from "../../redux/setLoginUser";
import { Amplify } from "aws-amplify";
import amplifyConfig from "../amplify-config";
import purpleLogo from "../../../src/assets/images/purpleKnowtexLogo.svg"

export default function Login({ setAuth }) {
  const [error, setError] = useState("");

  useEffect(() => {
    setTimeout(() => {
      setError("");
    }, 3000)
  }, [error])

  Amplify.configure(amplifyConfig);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();

  const submitData = async (data) => {
    
    await authenticate(data.name, data.password);
  };
  
  const authenticate = async (Username, Password) =>
    await new Promise((resolve, reject) => {
      const user = new CognitoUser({ Username, Pool });
      const authDetails = new AuthenticationDetails({ Username, Password });
      user.authenticateUser(authDetails, {
        onSuccess: (session) => {
          const accessToken = session.getAccessToken().getJwtToken();
          if (accessToken) {
            localStorage.setItem("token", accessToken);
            localStorage.setItem("username", Username);
            setAuth(true);
          }
          // Retrieve user attributes
          user.getUserAttributes((err, attributes) => {
            if (err) {
              console.error("Error getting user attributes", err);
              reject(err);
              return;
            }
            // console.log("Raw attributes:", attributes);
            const userAttributes = {};
            attributes.forEach((attribute) => {
              userAttributes[attribute.getName()] = attribute.getValue();
            });
            
            const org = userAttributes["custom:org"];
            const role = userAttributes["custom:role"];
            const email = userAttributes["email"];

            // adding role and org to localStorage
            localStorage.setItem("org", org);
            localStorage.setItem("role", role);
            localStorage.setItem("userEmail", email);
            
            // Dispatch user data to Redux store
            setLoginUser(dispatch, {
              username: Username,
              org: org,
              role: role,
              // Include other user data as needed
            });

            // isSuperUser in localStorage if they have both Knowtex and Admin Property
            // localStorage.setItem("isSuperUser", (org === "Knowtex" && role === "Admin"));
            // localStorage.setItem("isSuperUser", (false));

            resolve({ session, userAttributes });
          });
        },

        onFailure: (err) => {
          setError(err.message);
          reject(err);
          setAuth(false);
        },

        newPasswordRequired: (data) => {
          setError("New Password Required");
          resolve(data);
        },
      });
    });

  return (
    <div className="bg-left bg-auto bg-login-background w-dvw h-dvh flex flex-col items items-center">
      <img src={purpleLogo} className="w-[30%] h-[40%] my-[1rem]"></img>

      <div className="bg-white rounded-md shadow-drop-shadow w-[30%] h-[45%] flex flex-col justify-center my-[-4rem]">
        <h1 className="text-ktx-navy text-[1.5rem] self-center mb-[4rem] font-medium">Admin Dashboard</h1>
        <form className="flex flex-col items-center gap-[1.7rem]" onSubmit={handleSubmit(submitData)}>
          <div className="flex rounded-md bg-white shadow-knowtex-shadow w-[80%]">
            <svg className="self-center ml-[.4rem] w-6 h-6 text-light-grey dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 13h3.439a.991.991 0 0 1 .908.6 3.978 3.978 0 0 0 7.306 0 .99.99 0 0 1 .908-.6H20M4 13v6a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-6M4 13l2-9h12l2 9"/>
            </svg>
            <input
              type="text"
              placeholder="Username"
              name="name"
              className="text-light-grey py-[.7rem] w-[100%] italic border-none bg-none rounded-md"
              {...register("name", { required: true })}
              />
          </div>
          {error && <p className="w-[80%] tracking-wider m-[-1.6rem] text-ktx-red">{error}</p>}
          {errors.name && <p className="w-[80%] tracking-wider m-[-1.6rem] text-ktx-red">Username is Required!</p>}
          <div className="flex rounded-md bg-white shadow-knowtex-shadow w-[80%]">
            <svg className="self-center ml-[.4rem] w-6 h-6 text-light-grey dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 12a28.076 28.076 0 0 1-1.091 9M7.231 4.37a8.994 8.994 0 0 1 12.88 3.73M2.958 15S3 14.577 3 12a8.949 8.949 0 0 1 1.735-5.307m12.84 3.088A5.98 5.98 0 0 1 18 12a30 30 0 0 1-.464 6.232M6 12a6 6 0 0 1 9.352-4.974M4 21a5.964 5.964 0 0 1 1.01-3.328 5.15 5.15 0 0 0 .786-1.926m8.66 2.486a13.96 13.96 0 0 1-.962 2.683M7.5 19.336C9 17.092 9 14.845 9 12a3 3 0 1 1 6 0c0 .749 0 1.521-.031 2.311M12 12c0 3 0 6-2 9"/>
            </svg>
            <input
              type="password"
              placeholder="Password"
              name="password"
              className="text-light-grey py-[.7rem] w-[100%] italic border-none bg-none rounded-md"
              {...register("password", { required: true })}
            />
          </div>
          {errors.password && <p className="w-[80%] tracking-wider m-[-1.6rem] text-ktx-red">Password is Required!</p>}
          <button className="hover:bg-ktx-navy rounded-md bg-ktx-purple text-white py-[.7rem] w-[80%] tracking-widest my-[1rem]">Login</button>
        </form>
      </div>
    </div>
  );
}
