import React, { useState } from "react";
import Sidebar from "../Sidebar";

const Coding = ({ setAuth }) => {
  const [codingReview, setCodingReview] = useState("");
  const [LOS, setLOS] = useState("");
  const [jobName, setJobName] = useState("");
  const [patient, setPatient] = useState("");
  const [isUpdated, setIsUpdated] = useState(false);
  const [note, setNote] = useState("");
  const [patientsChange, setPatientsChange] = useState(false);

  //submits updated LOS and MDM to Postgres DB
  const editText = async (id) => {
    try {
      const body = { codingReview, LOS };

      const myHeaders = new Headers();

      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("token", localStorage.token);

      await fetch(`${process.env.REACT_APP_API_URL}/reviews/coding/${id}`, {
        method: "PUT",
        headers: myHeaders,
        body: JSON.stringify(body),
      });
      setIsUpdated(true);
      //window.location = "/";
    } catch (err) {
      console.error(err.message);
    }
  };

  //saves updated note to Postgres DB BUT DOES NOT CHANGE STATUS
  const saveText = async (id) => {
    try {
      const body = { note };

      const myHeaders = new Headers();

      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("token", localStorage.token);

      await fetch(`${process.env.REACT_APP_API_URL}/reviews/save/${id}`, {
        method: "PUT",
        headers: myHeaders,
        body: JSON.stringify(body),
      });
      setPatientsChange(true);

      //window.location = "/";
    } catch (err) {
      console.error(err.message);
    }
  };

  //retrieves SOAP note, code, and MDM from Postgres DB
  async function getNote(id) {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/reviews/patient/info/${id}`,
        {
          method: "GET",
        }
      );

      const parseRes = await response.json();
      setNote(parseRes[0].note);
      setCodingReview(parseRes[0].coding_information);
      setLOS(parseRes[0].los_code);
    } catch (err) {
      console.error(err.message);
    }
  }

  async function postData(url = "", data = {}, method = "GET") {
    // Default options are marked with *
    const response = await fetch(url, {
      method: method, // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      // Add the 'token' as a header to allow access to backend

      headers: {
        token: window.localStorage.getItem("token"),
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      //body: JSON.stringify(data) // body data type must match "Content-Type" header
    });
    return response.json(); // parses JSON response into native JavaScript objects
  }

  const handleJobChange = (event) => {
    setJobName(event.target.value);
  };

  // function to fetch the job info given by the given job name
  const fetchJobInfo = (event) => {
    var getTask_url = `${process.env.REACT_APP_DYNAMO_API_URL}/Prod/summarizejob?summarize_job_name=${jobName}`;
    postData(getTask_url).then((data) => {
      setPatient(data.patient_id);
      getNote(data.patient_id);
    });
  };
  return (
    <div className="flex">
      <div className="h-[100dvh]">
        <div className="flex h-[100%] fixed top-0">
          <Sidebar setAuth={setAuth}/>
        </div>
      </div>
      <div className="ml-[369px] flex overflow-x-hidden">
        <div className="flex flex-col">
          <div className="flex flex-col justify-center items-center gap-[2rem] w-[78dvw]">
            <h1 className="font-bold text-[28px] text-ktx-purple mt-[3rem]">Code Review</h1>
            <div className="flex w-[85%]">
              <div className="flex rounded-md bg-white shadow-knowtex-shadow w-[80%] h-[2.5rem]">
                <svg className="self-center ml-[.4rem] w-6 h-6 text-ktx-navy dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 7H5a2 2 0 0 0-2 2v4m5-6h8M8 7V5a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2m0 0h3a2 2 0 0 1 2 2v4m0 0v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-6m18 0s-4 2-9 2-9-2-9-2m9-2h.01" />
                </svg>
                <input
                  type="job"
                  placeholder="Job"
                  name="job"
                  required={true}
                  className="text-ktx-navy p-[.5rem] w-[100%] italic border-none bg-none rounded-md self-center"
                  value={jobName}
                  onChange={handleJobChange}
                />
              </div>
              <button className="disabled:bg-transparent-grey font-semibold hover:bg-ktx-navy rounded-md bg-ktx-purple ml-[1.7rem] tracking-widest text-white py-[.7rem] h-[2.5rem] px-[3rem] w-[20%] items-center flex justify-center"
                onClick={fetchJobInfo}
                disabled={!jobName.startsWith("Summarize")}>Get Job</button>
            </div>


          </div>

          <div className="flex flex-col justify-center items-center">
            <div className="flex mt-[2rem] w-[85%] shadow-knowtex-shadow rounded-md">
              <div className="flex flex-col w-[34%]">
                <h3 className="p-[.25rem] text-[1.25rem] text-white bg-dark-purple rounded-tl-md">AI Note</h3>
                <textarea id="transcript" value={note}
                  className="border-none resize-none h-[29.4rem] py-[1rem] bg-barely-purple text-ktx-navy rounded-bl-md"
                  onChange={(e) => setNote(e.target.value)} />
              </div>
              <div className="flex flex-col w-[33%]">
                <h3 className="p-[.25rem] text-[1.25rem] text-white bg-dark-purple">LOS Code</h3>
                <textarea id="transcript" value={LOS}
                  className="border-none resize-none h-[29.4rem] py-[1rem] bg-barely-purple text-ktx-navy rounded-bl-md"
                  onChange={(e) => setLOS(e.target.value)} />
              </div>
              <div className="flex flex-col w-[33%]">
                <h3 className="p-[.25rem] text-[1.25rem] text-white bg-dark-purple rounded-tr-md">Coding Review</h3>
                <textarea id="note" value={codingReview}
                  onChange={(e) => setCodingReview(e.target.value)}
                  className="border-none resize-none h-[29.4rem] py-[1rem] bg-barely-purple text-ktx-navy rounded-br-md" />
              </div>
            </div>
          </div>

          <div className="my-[2rem] flex justify-end w-[92.5%] gap-[1.7rem]">
            <button
              className="hover:text-ktx-navy text-ktx-purple underline font-semibold"
              type="button"
              onClick={() => saveText(patient)}
            >
              Save Draft
            </button>

            <button type="button" onClick={() => editText(patient)}
              className="font-semibold hover:bg-ktx-navy rounded-md bg-ktx-purple text-white py-[.7rem] h-[2.5rem] px-[2rem] w-auto items-center flex justify-center tracking-widest"
            >
              Upload Note
            </button>

            {isUpdated && <div>Coding Updated</div>}
          </div>

        </div>
      </div>
    </div>
  );
};
export default Coding;
