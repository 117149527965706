import { queryDataset, deleteDataset, queryAllDatasets } from "../../utils/fine-tuning-functions";
import { useState } from "react";
import DisplayDatasetModal from "./DisplayDatasetModal";

export default function SelectDatasets({ datasets, setDatasets }) {
  const [selectedDataset, setSelectedDataset] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = async (datasetId) => {
    setOpenModal(true);
    setSelectedDataset(await queryDataset(datasetId));
  }

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedDataset(null);
  }

  const handleDeleteDataset = async (datasetId) => {
    await deleteDataset(datasetId);
    setDatasets(await queryAllDatasets());
  }

  return (
    <>
      {datasets !== null ? (
        <div className="flex flex-col items-center">
          <h2 className="font-bold text-[23px] text-ktx-purple my-[2rem]">
            Select Datasets
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 w-full max-w-[80%]">
            {datasets.map(dataset => (
              <div key={dataset.id} className="bg-ktx-purple text-white p-4 rounded-md shadow-knowtex-shadow gap-1 flex flex-col m-2">
                <h3 className="font-bold text-lg">{dataset.name}</h3>
                <p className="text-sm">{dataset.location}</p>
                <div className="flex gap-4 justify-between">
                  <button onClick={() => handleOpenModal(dataset.id)} className="bg-white hover:bg-ktx-navy text-ktx-purple p-2 rounded-md min-w-[100px] w-20% mx-auto hover:text-white">Select</button>
                  <button onClick={() => handleDeleteDataset(dataset.id)} className="bg-ktx-red hover:bg-red-700 text-white p-2 rounded-md min-w-[100px] w-20% mx-auto">Delete</button>
                </div>
              </div>
            ))}
          </div>
          {openModal && <DisplayDatasetModal dataset={selectedDataset} closeModal={handleCloseModal} />}
        </div>
      ) : (
        <span className="w-6 h-6 border-2 border-dashed rounded-full animate-spin border-ktx-purple mx-auto"></span>
      )}
    </>
  )
}