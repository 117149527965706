import { useState, useEffect } from 'react';
import { getOneOrg, queryTotalNotesForOrg } from '../../utils/functions';
import GetPatientsById from './OrgSelectorComponents/GetPatientsById';
import OrgDetails from './OrgSelectorComponents/OrgDetails';
import NoteStatus from './OrgSelectorComponents/NoteStatus';
import ProcessingNotes from './OrgSelectorComponents/ProcessingNotes';

export default function OrgSelector({ asyncFunction }) {
  const [orgs, setOrgs] = useState(null); // orgs original array

  const [org, setOrg] = useState("");
  const [orgQuery, setOrgQuery] = useState("");
  const [orgSubmitted, setOrgSubmitted] = useState(false);

  const [refinedOrgs, setRefinedOrgs] = useState(null);
  const [doctors, setDoctors] = useState(null);

  const [refinedDoctors, setRefinedDoctors] = useState(null);
  const [doctorQuery, setDoctorQuery] = useState("");

  const [copied, setCopied] = useState("");

  const [getOrgData, setGetOrgData] = useState(null);
  const [openOrgModal, setOpenOrgModal] = useState(false);

  // const [showRRLTooltip, setShowRRLTooltip] = useState(false);

  useEffect(() => {
    if (asyncFunction) {
      (async () => {
        setOrgs(await asyncFunction());
      })()
    }
  }, [])

  useEffect(() => {
    if (orgQuery && orgs.length) {
      setRefinedOrgs(orgs.filter(el => el.toLowerCase().includes(orgQuery.toLowerCase())));
    }

    if (orgQuery === "") {
      setRefinedOrgs(null);
    }

  }, [orgQuery])

  useEffect(() => {
    if (doctorQuery && doctors.length) {
      setRefinedDoctors(doctors.filter(el => el.user_name.toLowerCase().includes(doctorQuery.toLowerCase()) || el.user_email.toLowerCase().includes(doctorQuery.toLowerCase())));
    }
  })

  const handleSetOrg = (e) => {
    setOrg(e.target.value);
  }

  const submitFormForOrg = async (e) => {
    e.preventDefault();

    setOrgSubmitted(true);
    setDoctors(await getOneOrg(org));
  }

  const resetOrgForm = () => {
    setOrgSubmitted(false);
    setDoctorQuery("");
    setOrg("");
    setOrgQuery("");
    setRefinedDoctors(null);
    setGetOrgData(null);
  }

  const getAllTheNotes = async () => {
    setOpenOrgModal(true);

    if (getOrgData === null) {

      const allNotes = await queryTotalNotesForOrg(doctors.map(el => ({ userName: el.user_name.split(" ").join(""), userEmail: el.user_email })));
      setGetOrgData(allNotes);
    }

  }

  // const handleShowRRLTooltip = () => {
  //   setShowRRLTooltip(true);

  //   setTimeout(() => {
  //     setShowRRLTooltip(false);
  //   }, 2000)
  // }

  const copyToClipboard = async (e) => {
    navigator.clipboard.writeText(e.target.innerText);

    setCopied(`Successfully copied: ${e.target.innerText}`);

    setTimeout(() => {
      setCopied("");
    }, 1000)
  }

  return (
    <>
      {!orgSubmitted &&
        <form className="flex gap-2 w-full mb-4 justify-between" onSubmit={submitFormForOrg}>
          <input className="w-[30%] rounded-md" type='text' value={orgQuery} onChange={(e) => setOrgQuery(e.target.value)} placeholder="Search" />
          <select className="w-[30%] rounded-md" placeholder='Org' onChange={handleSetOrg} value={org}>
            <option value="" disabled>Org</option>
            {refinedOrgs ?
              refinedOrgs.map((el, i) => (
                <option key={i} value={el}>{el}</option>
              )) : orgs && orgs.map((el, i) => (
                <option key={i} value={el}>{el}</option>
              ))
            }
          </select>
          <button disabled={org === ""} className="w-[30%] rounded-md border-sage-green border-[1px] text-sage-green hover:bg-sage-green hover:text-white font-semibold" type="submit">Submit</button>
        </form>
      }
      {orgSubmitted &&
        <>
          <input className="w-[50%] rounded-md" type='text' value={doctorQuery} onChange={(e) => setDoctorQuery(e.target.value)} placeholder="Doctor Name / Email" />
          <table className='w-full max-h-[400px] relative mb-4'>
            <button onClick={resetOrgForm} className='absolute right-0 top-[-60px] rounded-md border-sage-green border-[1px] text-sage-green hover:bg-sage-green hover:text-white py-2 px-4 font-semibold'>New Org</button>
            <button onClick={getAllTheNotes} className='absolute right-[20%] top-[-60px] rounded-md border-sage-green border-[1px] text-sage-green hover:bg-sage-green hover:text-white py-2 px-4 font-semibold'>Org Notes</button>
            {openOrgModal && <OrgDetails getOrgData={getOrgData} org={org} setOpenOrgModal={setOpenOrgModal} doctorsArr={doctors} />}
            <thead>
              <tr className='py-2 px-4 sticky'>
                <th className='border-b w-[30%] text-left'>Name</th>
                <th className='border-b max-w-[20px]'>Email</th>
                <th className='border-b px-2 text-center'>Int</th>
                <th className='border-b w-[20%]'>Status</th>
                {/* <th onMouseEnter={handleShowRRLTooltip} className='border-b w-[55%] relative'>{showRRLTooltip && <span className='absolute bottom-[2rem] left-[-5rem] bg-white rounded-md p-4 w-[300px] shadow-knowtex-shadow'>Ready to Record | Review | Locked</span> }Status</th> */}
                <th className='border-b'>Patients</th>
                <th className='border-b'>PN</th>
              </tr>
            </thead>
            <tbody>
              {doctorQuery && refinedDoctors !== null ?
                refinedDoctors.map(row => (
                  <tr className='py-2 px-4 hover:bg-light-purple' key={row.user_id}>
                    <td className=''>{row.user_name}</td>
                    <td className='relative'><button onClick={copyToClipboard}>{row.user_email}</button>{copied && copied.includes(row.user_email) && <span className='absolute right-0 top-[-55px] bg-white shadow-knowtex-shadow rounded-md p-3'>{copied}</span>}</td>
                    <td className='w-fit'>{row.access_token ? "Yes" : "No"}</td>
                    <td className='text-center'><NoteStatus doctor_id={row.user_id} access_token={row.access_token} doctor_name={row.user_name} /></td>
                    <td className='text-center'><GetPatientsById doctor_id={row.user_id} doctor_name={row.user_name} /></td>
                    <td className='text-center'><ProcessingNotes doctor_id={row.user_id} /></td>
                  </tr>
                )) :
                doctors !== null ? doctors.map(row => (
                  <tr className='py-2 px-4 hover:bg-light-purple' key={row.user_id}>
                    <td className=''>{row.user_name}</td>
                    <td className='relative'><button onClick={copyToClipboard}>{row.user_email}</button>{copied && copied.includes(row.user_email) && <span className='absolute right-0 top-[-55px] bg-white shadow-knowtex-shadow rounded-md p-3'>{copied}</span>}</td>
                    <td className='w-fit'>{row.access_token ? "Yes" : "No"}</td>
                    <td className='text-center'><NoteStatus doctor_id={row.user_id} access_token={row.access_token} doctor_name={row.user_name} /></td>
                    <td className='text-center'><GetPatientsById doctor_id={row.user_id} doctor_name={row.user_name} /></td>
                    <td className='text-center'><ProcessingNotes doctor_id={row.user_id} /></td>
                  </tr>
                )) : <span className="w-6 h-6 border-2 border-dashed rounded-full animate-spin border-ktx-purple"></span>}
            </tbody>
          </table>
        </>
      }
    </>
  );
};

