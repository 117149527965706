const mytext = `SUBJECTIVE 
--------------
Chief Complaint: 
 
History of Present Illness: 

OBJECTIVE 
------------
Physical Exam: 
- Palpable dorsalis pedis and posterior tibialis pulse to 

ASSESSMENT/PLAN
----------------------
Diagnoses: 

Follow-up: 

Patient has given prior verbal consent to have the conversation recorded and summarized by the Knowtex software.`;

export default mytext;
