import { useState } from "react";
import { importHuggingFaceDataset } from "../../utils/fine-tuning-functions";

export default function HuggingFace({ updateType }) {
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      await importHuggingFaceDataset(inputValue);
    } catch (error) {
      console.error("Error importing dataset:", error);
    }
  }

  return (
    <div className="flex flex-col items-center">
      <h4 className="font-bold text-[16px] text-ktx-purple my-[2rem]">
        <button onClick={updateType}>Hugging Face</button>
      </h4>
      <form onSubmit={handleSubmit} className="w-full max-w-sm flex justify-center items-center gap-4">
        <input
          type="text"
          id="inputField"
          value={inputValue}
          onChange={handleInputChange}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          placeholder="Hugging Face Slug"
        />
        <button
          type="submit"
          className="bg-ktx-purple hover:bg-ktx-navy text-white font-bold py-2 px-3 rounded focus:outline-none focus:shadow-outline"
        >
          Submit
        </button>
      </form>
    </div>
  )
}