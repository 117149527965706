import React, { useState } from "react";
import TemplatesModal from "./TemplatesModal";
import medOncoTemplate from "./ExampleTemplates/medOncoTemplate";
import radOncoTemplate from "./ExampleTemplates/radOncoTemplate";
import podiatryTemplate from "./ExampleTemplates/podiatryTemplate";
import Sidebar from "../Sidebar";

const Templates = ({ setAuth }) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const templates = [
    { name: "Med-Onc Template", note: medOncoTemplate },
    { name: "Rad-Onc Template", note: radOncoTemplate },
    { name: "Podiatry Template", note: podiatryTemplate },
  ];

  function sortTemplatesByName(templates) {
    return templates.sort((a, b) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
  }

  const sortedTemplates = sortTemplatesByName(templates);

  const handleTemplateClick = (template) => {
    setSelectedTemplate(template);
    setShowModal(true);
  };

  return (
    <div className="w-screen h-screen">
      <Sidebar setAuth={setAuth} />
      <div className="px-20 flex items-center flex-col ml-[369px]">
        <p className="my-14 font-bold text-[40px] text-ktx-purple">Templates</p>
        <div className="w-full flex-wrap flex justify-center">
          {sortedTemplates.length !== 0 &&
            sortedTemplates.map((template, i) => (
              <div
                key={i}
                className="mt-5 mx-3 cursor-pointer flex rounded-t-[18px] items-center justify-center h-[80px] w-[300px] hover:bg-ktx-navy bg-ktx-purple"
                onClick={() => handleTemplateClick(template)}>
                <button className="font-extrabold text-white text-[20px]">
                  {template.name}
                </button>
              </div>
            ))}
        </div>
      </div>
      {showModal && (
        <TemplatesModal
          template={selectedTemplate}
          closeModal={() => setShowModal(false)}
        />
      )}
    </div>
  );
};

export default Templates;
