import { useState } from "react";
import { createNewModel } from "../../utils/fine-tuning-functions";

export default function CreateMIModel() {
  const [modelData, setModelData] = useState({
    modelName: "",
    modelDescription: ""
  });

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setModelData((prevModelData) => ({
      ...prevModelData,
      [id]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      await createNewModel(modelData);
      alert("Model Created Successfully");

      setModelData({
        modelName: "",
        modelDescription: ""
      });
    } catch (error) {
      console.error("Error creating model:", error);
    }

  }

  return (
    <div className="flex flex-col items-center">
      <h2 className="font-bold text-[23px] text-ktx-purple my-[2rem]">
        Create MI Model
      </h2>
      <form onSubmit={handleSubmit} className="w-full max-w-sm flex flex-col gap-4">
        <input
          type="text"
          id="modelName"
          value={modelData.modelName}
          onChange={handleInputChange}
          required
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          placeholder="Model Name"
        />
        <input
          type="text"
          id="modelDescription"
          value={modelData.modelDescription}
          onChange={handleInputChange}
          required
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          placeholder="Model Description"
        />
        <button
          type="submit"
          className="bg-ktx-purple hover:bg-ktx-navy text-white font-bold py-2 px-3 rounded focus:outline-none focus:shadow-outline"
        >
          Submit
        </button>
      </form>
    </div>
  )
}