import { useState, useEffect } from 'react';
import { querySingleDoctorData } from '../../utils/functions';
import NoteStatus from './OrgSelectorComponents/NoteStatus';
import ProcessingNotes from './OrgSelectorComponents/ProcessingNotes';
import GetPatientsById from './OrgSelectorComponents/GetPatientsById';

export default function DocSelector({ asyncFunction }) {
  const [docs, setDocs] = useState(null); // docs original array
  
  const [doc, setDoc] = useState("");
  const [docQuery, setDocQuery] = useState("");
  const [docSubmitted, setDocSubmitted] = useState(false);

  const [refinedDocs, setRefinedDocs] = useState(null);
  const [doctors, setDoctors] = useState(null);

  const [refinedDoctors, setRefinedDoctors] = useState(null);
  const [doctorQuery, setDoctorQuery] = useState("");

  const [copied, setCopied] = useState("");

  const [getDocData, setGetDocData] = useState(null);
  const [openDocModal, setOpenDocModal] = useState(false);

  useEffect(() => {
    if (asyncFunction) {
      (async () => {
        setDocs(await asyncFunction());
      })()
    }
  }, [])

  useEffect(() => {
    if (docQuery && docs.length) {
      setRefinedDocs(docs.filter(el => el.user_name.toLowerCase().includes(docQuery.toLowerCase()) || el.user_email.toLowerCase().includes(docQuery.toLowerCase())));
    }

  }, [docQuery])

  useEffect(() => {
    if (docSubmitted) {
      (async () => {
        setGetDocData(await querySingleDoctorData(doc));
      })();
    }
  }, [docSubmitted])

  const handleSetDoc = (e) => {
    setDoc(e.target.value);
  }

  const resetDocForm = () => {
    setDocSubmitted(false);
    setDoc("");
    setDocQuery("");
    setRefinedDocs(null);
    setGetDocData(null);
  }

  const copyToClipboard = async (e) => {
    navigator.clipboard.writeText(e.target.innerText);

    setCopied(`Successfully copied: ${e.target.innerText}`);

    setTimeout(() => {
      setCopied("");
    }, 1000)
  }

  const submitFormForDoc = async (e) => {
    e.preventDefault();

    setDocSubmitted(true);
  }

  return (
    <>
      {!docSubmitted &&
        <form className="flex gap-2 w-full mb-4 justify-between" onSubmit={submitFormForDoc}>
          <input className="w-[30%] rounded-md" type='text' value={docQuery} onChange={(e) => setDocQuery(e.target.value)} placeholder="Search" />
          <select className="w-[30%] rounded-md" placeholder='Doc' onChange={handleSetDoc} value={doc}>
            <option value="" disabled>Doctor</option>
            {refinedDocs ?
              refinedDocs && refinedDocs.map((el, i) => (
                <option key={i} value={el.user_id}>{el.user_name}</option>
              )) : docs && docs.map((el, i) => (
                <option key={i} value={el.user_id}>{el.user_name}</option>
              ))
            }
          </select>
          <button disabled={doc === ""} className="w-[30%] rounded-md border-sage-green border-[1px] text-sage-green hover:bg-sage-green hover:text-white font-semibold" type="submit">Submit</button>
        </form>
      }
      {docSubmitted &&
        <>
          {getDocData !== null ?
            <>
              <h3 className='text-[22px] font-black'>{getDocData.userName}</h3>
              <table className='w-full max-h-[400px] relative mb-4'>
                <button onClick={resetDocForm} className='absolute right-0 top-[-60px] rounded-md border-sage-green border-[1px] text-sage-green hover:bg-sage-green hover:text-white py-2 px-4 font-semibold'>New Doctor</button>
                <thead>
                  <tr className='py-2 px-4 sticky'>
                    <th className='border-b max-w-[20px] text-left'>Email</th>
                    <th className='border-b px-2 text-center'>Int</th>
                    <th className='border-b w-[20%]'>Status</th>
                    <th className='border-b'>Patients</th>
                    <th className='border-b'>PN</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className='py-2 px-4 hover:bg-light-purple' key={"id"}>
                    <td className='relative'><button onClick={copyToClipboard}>{getDocData.userEmail}</button>{copied && copied.includes(getDocData.userEmail) && <span className='absolute right-0 top-[-55px] bg-white shadow-knowtex-shadow rounded-md p-3'>{copied}</span>}</td>
                    <td className='w-fit text-center'>{getDocData.accessToken ? "Yes" : "No"}</td>
                    <td className='text-center'><NoteStatus doctor_id={doc} access_token={getDocData.accessToken} doctor_name={getDocData.userName} /></td>
                    <td className='text-center'><GetPatientsById doctor_id={doc} doctor_name={getDocData.userName} /></td>
                    <td className='text-center'><ProcessingNotes doctor_id={doc} /></td>
                  </tr>
                </tbody>
              </table>
            </> :
            <span className="w-6 h-6 border-2 border-dashed rounded-full animate-spin border-ktx-purple"></span>
          }
        </>
      }
    </>
  );
};

