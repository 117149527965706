import { useState, useEffect } from "react";

export default function JokeWidget({ showJoke }) {
  const [currJoke, setCurrJoke] = useState(null);

  useEffect(() => {
    (async () => {
      const res = await fetch('https://icanhazdadjoke.com/', { headers: { 'Accept': 'application/json' } })

      try {
        const data = await res.json();

        setCurrJoke(data.joke);
      } catch (error) {
        console.error('Error:', error);
      }
    })()

  }, [])



  return (
    <>
      {showJoke && (
        <div className="absolute w-auto h-auto text-ktx-navy mx-auto gap-2 bg-barely-purple text-[18px] rounded-md p-4 text-center flex flex-col items-center justify-center">
          <svg className="h-10 text-gray-800 dark:text-white mb-2 pb-2 border-b-2 w-full" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 9h.01M8.99 9H9m12 3a9 9 0 1 1-18 0 9 9 0 0 1 18 0ZM6.6 13a5.5 5.5 0 0 0 10.81 0H6.6Z" />
          </svg>

          <p>{currJoke}</p>
        </div>
      )
      }
    </>
  )
}