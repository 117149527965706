import { useState, useEffect } from "react";
import { queryDoctorsWithProcessingNotes, queryDoctorsWithPlgStatus } from "../../utils/functions";
import ProcessingNotesList from "./HomeWidgetInteractions/ProcessingNotesList";
import PLGUsersList from "./HomeWidgetInteractions/PLGUsersList";

export default function HomeWidget({ displayText, variableProp, asyncFunction, appendS = false, helperScript }) {
  const [valueProp, setValueProp] = useState(null);
  const [helperData, setHelperData] = useState(null);
  const [openModal, setOpenModal] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      const result = await asyncFunction();
      setValueProp(result);
    };

    if (helperScript === "Processing") {
      const intervalId = setInterval(fetchData, 3000);

      // Clear the interval when the component unmounts
      return () => clearInterval(intervalId);
    } else {
      fetchData();
    }
  }, [valueProp]);

  const executeHelperFunction = async (e) => {
    e.preventDefault();

    if (helperScript === "Processing") {
      setHelperData(await queryDoctorsWithProcessingNotes());
      setOpenModal("Processing");
    } else if (helperScript === "PLG Users") {
      setHelperData(await queryDoctorsWithPlgStatus());
      setOpenModal("PLG Users");
    }
  }

  const handleDisabledButton = () => {
    return !(helperScript === "Processing" || helperScript === "PLG Users");
  }

  return (
    <>
      {asyncFunction ? (
        <div className="shadow-knowtex-shadow p-4 rounded-md w-[20%] flex flex-col items-center justify-center">
          <h4 className="font-semibold text-ktx-navy">{displayText}</h4>
          {valueProp !== null ? (
            helperScript === "Processing" || helperScript === "PLG Users" ? (
              <button onClick={executeHelperFunction}>
                <h3 className="text-sage-green text-[24px] font-semibold">
                  {!appendS ? valueProp : `${valueProp.toFixed(2)}s`}
                </h3>
              </button>
            ) : (
              <h3 className="text-sage-green text-[24px] font-semibold">
                {!appendS ? valueProp : `${valueProp.toFixed(2)}s`}
              </h3>
            )
          ) : (
            <div className="w-6 h-6 border-2 border-dashed rounded-full animate-spin border-ktx-purple"></div>
          )}
          {openModal === "Processing" && <ProcessingNotesList helperData={helperData} setOpenModal={setOpenModal} />}
          {openModal === "PLG Users" && <PLGUsersList helperData={helperData} setOpenModal={setOpenModal} />}
          {/* TODO: comparison for last week <span></span> */}
        </div>
      ) : (
        <div className="shadow-knowtex-shadow p-4 rounded-md w-[20%] flex flex-col items-center justify-center">
          <h4 className="font-semibold text-ktx-navy">{displayText}</h4>
          {variableProp !== null ? <h3 className="text-sage-green text-[24px] font-semibold">{variableProp}</h3> : <div className="w-6 h-6 border-2 border-dashed rounded-full animate-spin border-ktx-purple"></div>}
          {/* TODO: comparison for last week <span></span> */}
        </div>
      )}
    </>
  )

}