import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Sidebar from "./Sidebar";

const Summarizer = ({ setAuth }) => {
  const { register, handleSubmit } = useForm();
  const [jobData, setJobData] = useState("");
  const [patientIDs, setPatientIDs] = useState("")
  const [isLoading, setIsLoading] = useState(false);
  const [copiedMap, setCopiedMap] = useState([]);

  const jobDataPatientIDs = (jobs) => {
    let summarizedJobs = jobs.map((job)=> job.summarize_job_name);
    setPatientIDs(summarizedJobs.map((summarizedJob) => {
      let parts = summarizedJob.split("-");
      let patientID = parts[7].split(".")[0]
      return patientID
    }))
  }

  const copyClick = async (index) => {
    await setCopiedMap(jobData.map((job) => false));
    copiedMap[index] = true;
    setCopiedMap(copiedMap);

    setTimeout(() => {
      copiedMap[index] = false;
      setCopiedMap(copiedMap);
    }, 100);
  };

  const onSubmit = (data) => {
    setIsLoading(true);
    //creates api url based on values entered
    let queryURL = `${process.env.REACT_APP_DYNAMO_API_URL}/Prod/summarizejobs?`;
    if (
      //no values entered except for date (date is required)
      data.doctorName === "" &&
      data.patientID === "" &&
      data.is_reviewed === "null"
    ) {
      queryURL =
        queryURL +
        new URLSearchParams({
          audio_upload_date: data.audio_upload_date,
        });
      //console.log(queryURL);
    } else if (
      //Only Doctor name entered
      data.doctorName !== "" &&
      data.patientID === "" &&
      data.is_reviewed === "null"
    ) {
      queryURL =
        queryURL +
        new URLSearchParams({
          Doctor: data.doctorName,
          audio_upload_date: data.audio_upload_date,
        });
      //console.log(queryURL);
    } else if (
      //Only Patient ID entered
      data.doctorName === "" &&
      data.patientID !== "" &&
      data.is_reviewed === "null"
    ) {
      queryURL =
        queryURL +
        new URLSearchParams({
          patient_id: data.patientID,
          audio_upload_date: data.audio_upload_date,
        });
      //console.log(queryURL);
    } else if (
      //Only Is Reviewed value selected
      data.doctorName === "" &&
      data.patientID === "" &&
      data.is_reviewed !== "null"
    ) {
      queryURL =
        queryURL +
        new URLSearchParams({
          is_reviewed: data.is_reviewed,
          audio_upload_date: data.audio_upload_date,
        });
      //console.log(queryURL);
    } else if (
      //Doctor and Patient values entered
      data.doctorName !== "" &&
      data.patientID !== "" &&
      data.is_reviewed === "null"
    ) {
      queryURL =
        queryURL +
        new URLSearchParams({
          Doctor: data.doctorName,
          patient_id: data.patientID,
          audio_upload_date: data.audio_upload_date,
        });
      //console.log(queryURL);
    } else if (
      //Patient and Is Reviewed values entered
      data.doctorName === "" &&
      data.patientID !== "" &&
      data.is_reviewed !== "null"
    ) {
      queryURL =
        queryURL +
        new URLSearchParams({
          patient_id: data.patientID,
          is_reviewed: data.is_reviewed,
          audio_upload_date: data.audio_upload_date,
        });
      // console.log(queryURL);
    } else if (
      //Doctor and Is Reviewed values entered
      data.doctorName !== "" &&
      data.patientID === "" &&
      data.is_reviewed !== "null"
    ) {
      queryURL =
        queryURL +
        new URLSearchParams({
          Doctor: data.doctorName,
          is_reviewed: data.is_reviewed,
          audio_upload_date: data.audio_upload_date,
        });
      //console.log(queryURL);
    } else if (
      //All values entered
      data.doctorName !== "" &&
      data.patientID !== "" &&
      data.is_reviewed !== "null"
    ) {
      queryURL =
        queryURL +
        new URLSearchParams({
          Doctor: data.doctorName,
          patient_id: data.patientID,
          is_reviewed: data.is_reviewed,
          audio_upload_date: data.audio_upload_date,
        });
      //console.log(queryURL);
    }
    //calls API url
    postData(queryURL).then((data) => {
      setJobData(data.Jobs);
      jobDataPatientIDs(data.Jobs)
    });
  };

  async function postData(url = "", data = {}, method = "GET") {
    // Default options are marked with *
    const response = await fetch(url, {
      method: method, // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit

      // Add the 'token' as a header to allow access to backend

      headers: {
        token: window.localStorage.getItem("token"),
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      //body: JSON.stringify(data) // body data type must match "Content-Type" header
    });
    setIsLoading(false);
    return response.json(); // parses JSON response into native JavaScript objects
  }
  
  return (
    <div className="flex h-screen">
      <Sidebar setAuth={setAuth} />
      <div className="flex flex-col items-center flex-wrap w-screen ml-[369px]">
        <div className="flex flex-col items-center mt-10">
          <div className="flex justify-center font-bold text-[40px] mb-10">
            <p className="text-ktx-purple">Find Job</p>
          </div>
          <form onSubmit={handleSubmit(onSubmit)} className="mx-10">
            <div className="flex space-x-10">
              <input className="text-ktx-navy w-[230px] h-[45px] rounded-lg drop-shadow p-3" placeholder="Doctor Name" {...register("doctorName")} required />
              <input className="text-ktx-navy h-[45px] w-[160px] rounded-lg drop-shadow p-3" placeholder="Patient ID" {...register("patientID")} />
              <div className="flex">
                <label className="mt-3 mr-3 h-max align-center text-center">Is Reviewed? </label>
                <select className="text-ktx-navy text-[14px] font-normal w-auto h-[45px] text-center border-none rounded-lg drop-shadow p-3" {...register("is_reviewed")} required>
                  <option className="text-light-grey text-[14px]" value="null"></option>
                  <option className="text-light-grey" value="true">True</option>
                  <option className="text-light-grey" value="false">False</option>
                </select>
              </div>
              <div className="flex flex-col">
                <input className="text-ktx-navy h-[45px] w-[210px] rounded-lg drop-shadow p-3" placeholder="Upload Date..." {...register("audio_upload_date")} required />
                <label className="text-light-grey ml-2 mt-2 text-[10px]">(YYYY-MM-DD)</label>
              </div>
            </div>
            <div className="flex mt-10 justify-center">
              <button type="submit" className="text-[16px] font-extrabold hover:bg-ktx-navy bg-ktx-purple  w-[170px] h-[35px] text-white rounded-lg drop-shadow">
                Submit
              </button>
            </div>
          </form>
          <div className="overflow-y-auto flex justify-center">
            <div className="w-[900px] mt-10">
              <div className="w-full h-[35px] flex items-center justify-between bg-purple2 rounded-full">
                <p className="block w-full text-center font-bold text-white text-[20px]">Summarize Job Name</p>
                <p className="block w-[250px] text-center font-bold text-white text-[20px]">Patient ID</p>
              </div>
              <div className="flex justify-between">
                <div className="w-full">
                  {jobData.length !== 0 &&
                    jobData.map((job, index) => (
                      <div key={index} className=" h-[40px] flex mx-2 odd:bg-white even:bg-secondary-grey p-2 my-3 rounded-lg drop-shadow">
                        {!copiedMap[index] ?
                          <button onClick={() => {
                            navigator.clipboard.writeText(job.summarize_job_name);
                            copyClick(index);
                          }}>
                            <svg className="text-transparent-grey w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                              <path stroke="currentColor" strokeLinecap="round" stroke-width="2" d="M9 8v3a1 1 0 0 1-1 1H5m11 4h2a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1h-7a1 1 0 0 0-1 1v1m4 3v10a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1v-7.13a1 1 0 0 1 .24-.65L7.7 8.35A1 1 0 0 1 8.46 8H13a1 1 0 0 1 1 1Z" />
                            </svg>
                          </button>
                          :
                          <div>
                            <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 11.917 9.724 16.5 19 7.5" />
                            </svg>
                          </div>
                        }
                        <p className="pl-2 text-[14px]">{job.summarize_job_name}</p>
                      </div>
                    ))}
                </div>
                <div className="w-[250px]">
                  {patientIDs.length !== 0 &&
                    patientIDs.map((id, index) => (
                      <div key={index} className="h-[40px] flex mx-2 odd:bg-white even:bg-secondary-grey p-2 my-3 rounded-lg drop-shadow">
                        <p className="block text-center w-full pl-2 text-[14px]">{id}</p>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
          {isLoading === true && <label>Loading</label>}
        </div>
      </div>
    </div>
  );
};
export default Summarizer;