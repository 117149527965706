import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useEffect, useState } from 'react';

export default function WeeklyLineGraph({ lineGraphData, setShowGraph }) {
  const [reverseData, setReverseData] = useState(null);

  useEffect(() => {
    setReverseData([...lineGraphData].reverse());
  }, [])

  const handleGraphClose = () => {
    setShowGraph(false);
  }

  return (
    <div className="fixed bg-barely-purple w-[70%] left-[15%] max-h-[700px] flex flex-col rounded-md shadow-knowtex-shadow z-30 p-4">
      <h2 className="text-[26px] font-semibold text-center bg-barely-purple rounded-t-md py-2 mb-2">Graph</h2>
      <button className='absolute right-[20px] top-[10px] hover:text-ktx-navy font-bold text-[26px]' onClick={handleGraphClose}>×</button>
      {reverseData !== null ? (
        <ResponsiveContainer width="95%" height={400}>
          <LineChart
            data={reverseData}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis yAxisId="left" orientation="left" stroke="#8884d8" />
            <YAxis yAxisId="right" orientation="right" stroke="#82ca9d" />
            <Tooltip />
            <Legend />
            <Line yAxisId="left" type="monotone" dataKey="notes" stroke="#783BFF" activeDot={{ r: 8 }} />
            <Line yAxisId="right" type="monotone" dataKey="activationPercentage" stroke="#7FB1BA" />
          </LineChart>
        </ResponsiveContainer>
      ) : (
        <div className="w-full h-[400px] flex justify-center items-center">
          <div className="w-20 h-20 border-4 border-dashed rounded-full animate-spin-slow border-ktx-purple flex justify-center items-center"></div>
        </div>
      )}
    </div>
  )
}