import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import { useState, useEffect } from 'react';
import { queryMonthlyUsageForDoctor } from '../../../utils/functions';

const COLORS = ["#783BFF", "#7FB1BA"];

export default function GraphAndDetails({ patientCount, noteCount, closeModal, openModal, doctor_name, setShowNoteHistory, setNoteHistory, noteHistory }) {
  const [pieData, setPieData] = useState(null);

  useEffect(() => {
    if (noteCount !== null) {
      setPieData(
        [
          { name: "Total Notes", value: noteCount.total },
          { name: "This Week", value: noteCount.recent }
        ]
      )
    }

  }, [openModal])

  const customLegend = (value, entry) => {
    const { value: chartVal } = entry.payload;
    return `${value}: ${chartVal}`;
  }

  const handleHistoryClick = async () => {
    setShowNoteHistory(true);

    if (noteHistory === null) {
      const history = await queryMonthlyUsageForDoctor(doctor_name.split(" ").join(""));
      setNoteHistory(history);
    }
  }

  return (
    <div className='fixed left-[28%] top-[28%] w-[50%] flex flex-col items-center justify-center bg-light-purple p-4 rounded-md shadow-md z-10'>
      <button onClick={handleHistoryClick}>Show Months Past</button>
      <h2 className='text-[26px]'>Notes Generated for {doctor_name}</h2>
      <h3 className='text-[22px] mb-[1rem]'>Total Patients: {patientCount}</h3>
      <button className='absolute right-[20px] top-[10px] hover:text-ktx-navy font-bold text-[26px]' onClick={() => closeModal()}>×</button>

      <div className='relative w-full h-full z-10'>
        <ResponsiveContainer width={"96%"} height={400}>
          <PieChart>
            <Pie
              data={pieData}
              cx={"42%"}
              cy={"40%"}
              labelLine={false}
              outerRadius={160}
              fill="#8884d8"
              dataKey="value"
            >
              {pieData !== null && pieData.map((_, i) => (
                <Cell key={`cell-${i}`} fill={COLORS[i % COLORS.length]} />
              ))}
            </Pie>
            <Tooltip />
            <Legend layout="vertical" align="right" iconType="circle" iconSize={10} verticalAlign='middle' formatter={customLegend} />
          </PieChart>
        </ResponsiveContainer>

      </div>
    </div>
  )
}