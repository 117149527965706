export const getAllDoctorsCount = async () => {
  // on a good request it will return a count for all doctors currently in the database
  // else it will return an error code
  try {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/doctors/count-doctors`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "token": localStorage.token
        }
      });

    if (res.ok) {
      const data = await res.json();

      return data;
    } else {
      console.error({ "Error": `${res.status} ${res.statusText}` });
    }
  } catch (error) {
    console.error({ "Error": error.message })
  }
}

export const getActiveVsTotal = async () => {
  // on a good request it will return a count for all doctors currently in the database
  // else it will return an error code
  try {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/doctors/active-vs-total`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "token": localStorage.token
        }
      });

    if (res.ok) {
      const data = await res.json();

      return data;
    } else {
      console.error({ "Error": `${res.status} ${res.statusText}` });
    }
  } catch (error) {
    console.error({ "Error": error.message })
  }
}

export const getActiveDoctors = async () => {
  // on a good request it will return a count for all doctors currently in the database
  // else it will return an error code
  try {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/doctors/count-active-doctors`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "token": localStorage.token
        }
      });

    if (res.ok) {
      const data = await res.json();

      return data;
    } else {
      console.error({ "Error": `${res.status} ${res.statusText}` });
    }
  } catch (error) {
    console.error({ "Error": error.message })
  }
}

export const getAllPatientsCount = async () => {
  // on a good request it will return a count for all patients currently in the database
  // else it will return an error code
  try {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/patients/count-patients`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "token": localStorage.token
        }
      });

    if (res.ok) {
      const data = await res.json();

      return data;
    } else {
      console.error({ "Error": `${res.status} ${res.statusText}` });
    }
  } catch (error) {
    console.error({ "Error": error.message })
  }
}

export const getAllPlgUsers = async () => {
  // on a good request it will return a count for all patients currently in the database
  // else it will return an error code
  try {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/doctors/count-plg-users`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "token": localStorage.token
        }
      });

    if (res.ok) {
      const data = await res.json();

      return data;
    } else {
      console.error({ "Error": `${res.status} ${res.statusText}` });
    }
  } catch (error) {
    console.error({ "Error": error.message })
  }
}

export const getAllSpecialties = async () => {
  // on a good request it will return a count for all patients currently in the database
  // else it will return an error code
  try {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/doctors/specialties`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "token": localStorage.token
        }
      });

    if (res.ok) {
      const data = await res.json();
      const refinedData = combiningSpecialties(data);

      return refinedData;
    } else {
      console.error({ "Error": `${res.status} ${res.statusText}` });
    }
  } catch (error) {
    console.error({ "Error": error.message })
  }
}

export function renderSixMonthSpan() {
  const currentDate = new Date();

  function formatDate(date) {
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${month}/${year}`;
  }

  const endDate = formatDate(currentDate);

  const startDate = new Date();
  startDate.setMonth(currentDate.getMonth() - 5);
  const formattedStartDate = formatDate(startDate);

  return `${formattedStartDate} - ${endDate}`;
}

function combiningSpecialties(data) {
  const currentSpecialties = ["Orthopedics", "Oncology", "Podiatry", "Radiation Oncology", "Dentist", "Obstetrics and Gynecology", "Endocrinology", "Pediatric Neurology", "Urology", "Primary Care", "Astera", "Plastic Surgery", "PCSRI", "Coastal Cancer Center"];
  const custom = { name: "Custom", value: 0 };

  const res = [];

  for (let d of data) {
    if (!currentSpecialties.includes(d.name)) {
      custom.value += d.value;
    } else {
      res.push(d);
    }
  }

  res.push(custom);

  return res;
}

export const getTotalNotes = async () => {
  try {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/notes/count-notes`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "token": localStorage.token
        }
      });

    if (res.ok) {
      const data = await res.json();

      return data.itemCount;
    } else {
      console.error({ "Error": `${res.status} ${res.statusText}` });
    }
  } catch (error) {
    console.error({ "Error": error.message })
  }
}

export const getAverageSummarizeTime = async () => {
  try {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/notes/average-summarize-time`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "token": localStorage.token
        }
      });

    if (res.ok) {
      const data = await res.json();

      return data.averageSummarizeTimeInSeconds;
    } else {
      console.error({ "Error": `${res.status} ${res.statusText}` });
    }
  } catch (error) {
    console.error({ "Error": error.message })
  }
}

export const getAverageTranscribeTime = async () => {
  try {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/notes/average-transcription-time`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "token": localStorage.token
        }
      });

    if (res.ok) {
      const data = await res.json();

      return data.averageTranscribeTimeInSeconds;
    } else {
      console.error({ "Error": `${res.status} ${res.statusText}` });
    }
  } catch (error) {
    console.error({ "Error": error.message })
  }
}

export const getTotalMobileNotes = async () => {
  try {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/notes/jobs-with-conversation-id`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "token": localStorage.token
        }
      });

    if (res.ok) {
      const data = await res.json();

      return data.jobsWithoutConversationId;
    } else {
      console.error({ "Error": `${res.status} ${res.statusText}` });
    }
  } catch (error) {
    console.error({ "Error": error.message })
  }
}

export const getTotalWebappNotes = async () => {
  try {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/notes/jobs-with-conversation-id`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "token": localStorage.token
        }
      });

    if (res.ok) {
      const data = await res.json();

      return data.jobsWithConversationId;
    } else {
      console.error({ "Error": `${res.status} ${res.statusText}` });
    }
  } catch (error) {
    console.error({ "Error": error.message })
  }
}

// export const getNotesGenerated = async () => {
//   try {
//     const res = await fetch(`${process.env.REACT_APP_API_URL}/notes/notes-generated`,
//       {
//         method: "GET",
//         headers: {
//           "Content-Type": "application/json",
//           "token": localStorage.token
//         }
//       });

//     if (res.ok) {
//       const data = await res.json();

//       return data;
//     } else {
//       console.error({ "Error": `${res.status} ${res.statusText}` });
//     }
//   } catch (error) {
//     console.error({ "Error": error.message })
//   }   
// }

// export const getTotalApiCalls = async () => {
//   try {
//     const res = await fetch(`${process.env.REACT_APP_API_URL}/notes/count-api-calls`,
//       {
//         method: "GET",
//         headers: {
//           "Content-Type": "application/json",
//           "token": localStorage.token
//         }
//       });

//     if (res.ok) {
//       const data = await res.json();

//       return data;
//     } else {
//       console.error({ "Error": `${res.status} ${res.statusText}` });
//     }
//   } catch (error) {
//     console.error({ "Error": error.message })
//   }    
// }

export const getAllOrgs = async () => {
  try {

    const res = await fetch(`${process.env.REACT_APP_API_URL}/orgs/`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "token": localStorage.token
        }
      });

    if (res.ok) {
      const data = await res.json();

      return data;
    } else {
      console.error({ "Error": `${res.status} ${res.statusText}` });
    }

  } catch (error) {
    console.error({ "Error": error.message })
  }
}

export const getOneOrg = async (organization) => {
  try {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/orgs/specific-org/${organization}`);

    if (res.ok) {
      const data = await res.json();

      return data;
    } else {
      console.error({ "Error": `${res.status} ${res.statusText}` });
    }

  } catch (error) {
    console.error({ "Error": error.message })
  }
}

export const getPatientsById = async (user_id) => {
  try {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/patients/patients-by-id`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "token": localStorage.token,
        },
        body: JSON.stringify({ user_id: user_id })
      });

    if (res.ok) {
      const data = await res.json();

      return data;
    } else {
      console.error({ "Error": `${res.status} ${res.statusText}` });
    }

  } catch (error) {
    console.error({ "Error": error.message })
  }
}

export const queryNoteCountForDoctor = async (doctorString) => {
  try {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/notes/count-notes-by-doctor/${doctorString}`);

    if (res.ok) {
      const data = await res.json();

      return data;
    } else {
      console.error("Error querying notes by doctor:", res.statusText);
      alert("Error querying notes by doctor");
    }
  } catch (error) {
    console.error("Error querying notes by doctor:", error);
  }
}

export const queryNoteStatusForDoctor = async (doctorId) => {
  try {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/notes/note-status-for-doctor/${doctorId}`);

    if (res.ok) {
      const data = await res.json();

      return data;
    } else {
      console.error("Error querying notes by doctor:", res.statusText);
      alert("Error querying notes by doctor");
    }
  } catch (error) {
    console.error("Error querying notes by doctor:", error);
  }
}

export const queryWeeklyNoteStatusForDoctor = async (doctorId) => {
  try {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/notes/note-status-for-week-doctor/${doctorId}`);

    if (res.ok) {
      const data = await res.json();

      return data;
    } else {
      console.error("Error querying notes by doctor:", res.statusText);
      alert("Error querying notes by doctor");
    }
  } catch (error) {
    console.error("Error querying notes by doctor:", error);
  }
}

export const queryMonthlyUsageForDoctor = async (doctorString) => {
  try {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/notes/usage-by-doctor/${doctorString}`);

    if (res.ok) {
      const data = await res.json();

      return data;
    } else {
      console.error("Error querying monthly usage by doctor:", res.statusText);
      alert("Error querying monthly usage by doctor");
    }
  } catch (error) {
    console.error("Error querying monthly usage by doctor:", error);
  }
}

export const queryTotalNotesForOrg = async (doctorArray) => {
  try {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/notes/total-notes-by-doctors`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "token": localStorage.token,
        },
        body: JSON.stringify({ doctorArray: doctorArray })
      });

    if (res.ok) {
      const data = await res.json();

      return data;
    } else {
      console.error("Error querying monthly usage by doctor:", res.statusText);
      alert("Error querying monthly usage by doctor");
    }
  } catch (error) {
    console.error("Error querying monthly usage by doctor:", error);
  }
}

export const queryProcessingNotesForDoctor = async (doctorId) => {
  try {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/notes//get-processing-notes-for-doctor/${doctorId}`);

    if (res.ok) {
      const data = await res.json();

      return data;
    } else {
      console.error("Error querying processing notes by doctor:", res.statusText);
      alert("Error querying processing notes by doctor");
    }
  } catch (error) {
    console.error("Error querying processing notes by doctor:", error);
  }
}

export const queryNotesProcessing = async () => {
  try {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/notes/total-notes-processing`);

    if (res.ok) {
      const data = await res.json();

      return data;
    } else {
      console.error("Error querying processing notes:", res.statusText);
      alert("Error querying processing notes");
    }
  } catch (error) {
    console.error("Error querying processing notes:", error);
  }
}

export const queryDoctorsWithProcessingNotes = async () => {
  try {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/doctors/doctors-with-processing-notes`);

    if (res.ok) {
      const data = await res.json();

      return data;
    } else {
      console.error("Error querying doctors with processing notes:", res.statusText);
      alert("Error querying doctors with processing notes");
    }
  } catch (error) {
    console.error("Error querying doctors with processing notes:", error);
  }
}

export const queryAllDoctors = async () => {
  try {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/doctors/all-doctors`);

    if (res.ok) {
      const data = await res.json();

      return data;
    } else {
      console.error("Error querying all doctors:", res.statusText);
      alert("Error querying all doctors");
    }
  } catch (error) {
    console.error("Error querying all doctors:", error);
  }
}

export const querySingleDoctorData = async (doctorId) => {
  try {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/doctors/all-of-the-doctors-data/${doctorId}`);

    if (res.ok) {
      const data = await res.json();

      return data;
    } else {
      console.error("Error querying single doctor data:", res.statusText);
      alert("Error querying single doctor data");
    }
  } catch (error) {
    console.error("Error querying single doctor data:", error);
  }
}

export const querySingleDoctorDataToUpdate = async (doctorId) => {
  try {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/doctors/doctors-data-for-update/${doctorId}`);

    if (res.ok) {
      const data = await res.json();
      console.log(data);
      return data;
    } else {
      console.error("Error querying single doctor data:", res.statusText);
      alert("Error querying single doctor data");
    }
  } catch (error) {
    console.error("Error querying single doctor data:", error);
  }
}

export const querySpecialtiesForDropdown = async () => {
  try {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/doctors/specialties-for-update`);

    if (res.ok) {
      const data = await res.json();

      return data;
    } else {
      console.error("Error querying specialties for dropdown:", res.statusText);
      alert("Error querying specialties for dropdown");
    }
  } catch (error) {
    console.error("Error querying specialties for dropdown:", error);
  }
}

export const queryOrgsForDropdown = async () => {
  try {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/doctors/orgs-for-update`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "token": localStorage.token,
        }
      }
    );

    if (res.ok) {
      const data = await res.json();

      return data;
    } else {
      console.error("Error querying orgs for dropdown:", res.statusText);
      alert("Error querying orgs for dropdown");
    }
  } catch (error) {
    console.error("Error querying orgs for dropdown:", error);
  }
}

export const updateDoctorFields = async (doctorObj) => {
  try {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/doctors/update-doctor/${doctorObj.user_id}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "token": localStorage.token,
        },
        body: JSON.stringify(doctorObj)
      });

    if (res.ok) {
      const data = await res.json();

      return data;
    } else {
      console.error("Error updating doctor fields:", res.statusText);
      alert("Error updating doctor fields");
    }
  } catch (error) {
    console.error("Error updating doctor fields:", error);
  }
}

export const deleteDoctor = async (doctorId, username) => {
  try {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/doctors/delete-doctor/${doctorId}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "token": localStorage.token,
        },
        body: JSON.stringify({ username: username })
      });

    if (res.ok) {
      const data = await res.json();

      return data;
    } else {
      console.error("Error deleting doctor:", res.statusText);
      alert("Error deleting doctor");
    }
  } catch (error) {
    console.error("Error deleting doctor:", error);
  }
}

export const updatePassword = async (doctorObj) => {
  try {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/doctors/update-password/${doctorObj.user_id}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "token": localStorage.token,
        },
        body: JSON.stringify({ password: doctorObj.password, username: doctorObj.username, user_name: doctorObj.user_name, user_email: doctorObj.user_email, userEmail: doctorObj.userEmail })
      });

    if (res.ok) {
      const data = await res.json();

      return data;
    } else {
      console.error("Error updating password:", res.statusText);
      alert("Error updating password");
    }
  } catch (error) {
    console.error("Error updating password:", error);
  }
}

export const queryDoctorsWithPlgStatus = async () => {
  try {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/doctors/doctors-with-plg-status`);

    if (res.ok) {
      const data = await res.json();

      return data;
    } else {
      console.error("Error querying doctors with plg status:", res.statusText);
      alert("Error querying doctors with plg status");
    }
  } catch (error) {
    console.error("Error querying doctors with plg status:", error);
  }
}

export const queryWeeklyNotesLeaderboardForDoctors = async (doctorArray, weekNumber, org) => {
  try {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/notes/week-to-week-notes`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "token": localStorage.token,
        },
        body: JSON.stringify({ doctorArray, weekNumber, org })
      });

    if (res.ok) {
      const data = await res.json();

      return data;
    } else {
      console.error("Error querying weekly notes leaderboard for doctors:", res.statusText);
      alert("Error querying weekly notes leaderboard for doctors");
    }
  } catch (error) {
    console.error("Error querying weekly notes leaderboard for doctors:", error);
  }
}


export const generateDateRange = (weeks) => {
  const endDate = new Date();
  const startDate = new Date();

  const formattedDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${month}/${day}/${year}`;
  }

  if (weeks === 1) {
    startDate.setDate(startDate.getDate() - 7 + 1);
  } else {
    startDate.setDate(startDate.getDate() - (7 * weeks) + 1);
    endDate.setDate(endDate.getDate() - (7 * (weeks - 1)));
  }

  return `${formattedDate(startDate)} - ${formattedDate(endDate)}`;
}

export const getMRNByPatientId = async (patientId) => {
  try {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/reviews/patient/${patientId}`);

    if (res.ok) {
      const data = await res.json();

      return data;
    } else {
      console.error("Error querying MRN by patient id:", res.statusText);
      alert("Error querying MRN by patient id");
    }
  } catch (error) {
    console.error("Error querying MRN by patient id:", error);
  }
}

const cleanUpKeys = (key) => {
  let newKey = "";

  for (let i = 0; i < key.length; i++) {
    if (key[i] === "_") {
      newKey += " ";
    } else {
      newKey += key[i]
    }
  }

  return newKey.toUpperCase();
}

const generateDashes = (length) => {
  let dashes = "";
  for (let i = 0; i < length; i++) {
    dashes += "-";
  }

  return dashes;
}

export const formatChartData = (data) => {
  const keys = Object.keys(data);
  let formattedString = "";

  for (let key of keys) {
    const formattedKey = cleanUpKeys(key);

    if (!Array.isArray(data[key])) {
      formattedString += `${formattedKey} \n${generateDashes(key.length + Math.floor(key.length / 2))}\n${data[key]}\n\n`;

    } else {
      let internalString = `${formattedKey} \n${generateDashes(key.length + Math.floor(key.length / 2))}\n`;
      
      data[key].forEach((item) => {
        const internalKeys = Object.keys(item);

        for (let internalKey of internalKeys) {
          internalString += `${item[internalKey]}\n`;
        }

      })

      formattedString += `${internalString} \n\n`;
    }
  }

  return formattedString;
};