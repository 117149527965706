import { useState } from 'react';
import { uploadDataset } from '../../utils/fine-tuning-functions';

export default function FileUpload({ updateType }) {
  const [selectedFile, setSelectedFile] = useState("");

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (selectedFile.name.endsWith(".csv")) {
      await uploadDataset(selectedFile);
    } else if (selectedFile) {
      alert("Please upload a .csv file");
    }
    else {
      alert("No file selected");
    }
  };
  
  return (
    <div className="flex flex-col items-center">
      <h4 className="font-bold text-[16px] text-ktx-purple my-[2rem]">
        <button onClick={updateType}>Upload File</button>
      </h4>
      <form onSubmit={handleSubmit} className="w-full max-w-sm flex justify-center items-center gap-4">
        <input
          type="file"
          id="inputField"
          onChange={handleFileChange}
          className="shadow appearance-none border rounded-md w-full py-2 px-3 text-ktx-navy leading-tight focus:outline-none focus:shadow-outline"
        />
        <button
          type="submit"
          className="bg-ktx-purple hover:bg-ktx-navy text-white font-bold py-2 px-3 rounded focus:outline-none focus:shadow-outline"
        >
          Submit
        </button>
      </form>
    </div>
  );
}