import { LineChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Line, ResponsiveContainer } from "recharts";
import { useState, useEffect } from "react";

export default function LineGraph({ asyncFunction }) {
  const [dynamicLineData, setDynamicLineData] = useState(null);

  useEffect(() => {
    if (asyncFunction) {
      (async() => {
        setDynamicLineData(await asyncFunction());
      })()
    }
  }, [])

  return (
    <>
      {asyncFunction ? (
        <ResponsiveContainer width="95%" height={400}>
          <LineChart data={dynamicLineData}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="total" stroke="#783BFF" />
            <Line type="monotone" dataKey="active" stroke="#7FB1BA" />
          </LineChart>
        </ResponsiveContainer>
      ) : (
        <div className="w-full h-[400px] flex justify-center items-center">
          <div className="w-20 h-20 border-4 border-dashed rounded-full animate-spin-slow border-ktx-purple flex justify-center items-center"></div>
        </div>
      )}
    </>
  )
}