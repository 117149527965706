import LinkBarButton from "./LinkBarButton"
import InternalLinkBarButton from "./InternalLinkBarButton"

export default function RightLinkBar() {
  return (
    <div className="fixed right-0 top-0 flex flex-col w-[280px] h-dvh mt-[2%]">
      <div className="flex flex-col w-[80%] rounded-md text-ktx-navy gap-2">
        <h2 className="text-dark-purple font-bold text-[24px] text-center">Repo Links</h2>
        <LinkBarButton linkText="https://github.com/Knowtex-ai/admin-dashboard" displayText="Admin Dashboard" openNewWindow={true} />
        <LinkBarButton linkText="https://github.com/Knowtex-ai/knowtex-admin-backend" displayText="Admin Backend" openNewWindow={true} />
        <LinkBarButton linkText="https://github.com/Knowtex-ai/native-mobile-app" displayText="Native Mobile" openNewWindow={true} />
        <LinkBarButton linkText="https://github.com/Knowtex-ai/Knowtex-webapp" displayText="Web App" openNewWindow={true} />
        <LinkBarButton linkText="https://github.com/Knowtex-ai/knowtex-production-dashboard" displayText="Prod Dashboard" openNewWindow={true} />
      </div>
      <div className="flex flex-col w-[80%] gap-2 mt-[2rem]">
        <h2 className="text-dark-purple text-[24px] font-bold text-center">External Links</h2>
        <LinkBarButton linkText="https://us-east-1.console.aws.amazon.com/cloudwatch/home?region=us-east-1#dashboards/" displayText="AWS" openNewWindow={true}  />
        <LinkBarButton linkText="https://app.userflow.com/app/knowtex-2/flows" displayText="Userflow" openNewWindow={true} />
        <LinkBarButton linkText="https://app.logrocket.com/b0hpsh/knowtex-prod/sessions?persistForm=true&range=1w&rangeEnd=1722358518688&rangeStart=1721753718688&timeRange=86400000" displayText="LogRocket" openNewWindow={true} />
        <LinkBarButton linkText="https://knowtexai.sentry.io/issues/?project=4507862860693504&statsPeriod=7d" displayText="Sentry" openNewWindow={true} />
      </div>
      {/* <div className="flex flex-col w-[80%] gap-2 mt-[2rem]">
        <h2 className="text-dark-purple text-[24px] font-bold text-center">Internal Links</h2>
        <InternalLinkBarButton linkText="/fine-tuning" displayText="Model Fine-Tuning" />
      </div> */}
    </div>
  )
};