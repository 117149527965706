import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { Amplify, Auth } from "aws-amplify";
import amplifyConfig from "./components/amplify-config";
import Summarizer from "./components/Summarizer";
import ReviewTranscript from "./components/ReviewTranscript";
import Login from "./components/Login";
import ReviewNote from "./components/ReviewNote";
import Register from "./components/Register";
import Templates from "./components/Templates";
import Coding from "./components/Coding";
import Home from "./components/Home";
import RegisterDoctors from "./components/RegisterDoctors";
import UpdateDoctors from "./components/UpdateDoctors";
import FineTuning from "./components/FineTuning";
import Trace from "./components/Trace";

// Configure AWS Amplify
Amplify.configure(amplifyConfig);
Auth.configure(amplifyConfig);

const routes = [
  { path: "/login", component: Login, private: false },
  { path: "/", component: Home, private: true },
  { path: "/review-note", component: ReviewNote, private: true },
  { path: "/review-transcript", component: ReviewTranscript, private: true },
  { path: "/summarize", component: Summarizer, private: true },
  { path: "/templates", component: Templates, private: true },
  { path: "/coding", component: Coding, private: true },
  { path: "/register", component: Register, private: false },
  { path: "/register-doctors", component: RegisterDoctors, private: true },
  { path: "/update-doctors", component: UpdateDoctors, private: true },
  { path: "/fine-tuning", component: FineTuning, private: true },
  { path: "/trace", component: Trace, private: true },
];

const App = () => {
  const [isAuthenticated, setAuth] = useState(null);

  useEffect(() => {
    checkAuth();
  }, [isAuthenticated]);

  const checkAuth = () => {
    const accessToken = window.localStorage.getItem("token");
    setAuth(accessToken ? true : false);
  };

  return isAuthenticated !== null ? (
    <Router>
      <Routes>
        {routes.map(({ path, component: Component, private: isPrivate }) => (
          <Route
            key={path}
            path={path}
            element={
              isAuthenticated === isPrivate ? (
                <Component setAuth={setAuth} />
              ) : (
                <Navigate to={isPrivate ? '/login' : '/'} />
              )
            }
          />
        ))}
      </Routes>
    </Router>
  ) : null;
};

export default App;
