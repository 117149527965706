export default function LinkBarButton({ linkText, displayText, openNewWindow }) {
  return (
    <>
    { openNewWindow ? (
      <a href={linkText} target="_blank" rel="noopener noreferrer" className="flex bg-sage-green hover:bg-teal-700 rounded-md justify-center items-center w-full text-white py-2 gap-2">{displayText}
        <svg className="w-5 h-5 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 12H5m14 0-4 4m4-4-4-4" />
        </svg>
      </a>
    ) : (
      <a href={linkText} className="flex bg-sage-green hover:bg-teal-700 rounded-md justify-center items-center w-full text-white py-2 gap-2">{displayText}
        <svg className="w-5 h-5 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 12H5m14 0-4 4m4-4-4-4" />
        </svg>
      </a>
    )}
    </>
  )
}