import { BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Bar, ResponsiveContainer } from "recharts";
import { useState, useEffect } from "react";

export default function ChartAndDetails({ noteHistory, closeModal, openModal, doctor_name, setShowNoteHistory, showNoteHistory }) {
  const [dynamicLineData, setDynamicLineData] = useState(null);
  
  useEffect(() => {
    if (noteHistory !== null) {
      setDynamicLineData(noteHistory);
    }
    
  }, [showNoteHistory])

  return (
    <>
      {noteHistory !== null && noteHistory.length !== 0 ? (
        <div className='fixed left-[28%] top-[28%] w-[50%] flex flex-col items-center justify-center bg-light-purple p-4 rounded-md shadow-md z-10'>
          <button onClick={() => setShowNoteHistory(false)}>Show Total Notes</button>
          <h2 className='text-[26px] mb-[2rem]'>Months Past: {doctor_name}</h2>
          <button className='absolute right-[20px] top-[10px] hover:text-ktx-navy font-bold text-[26px]' onClick={() => closeModal()}>×</button>

          <div className='relative w-full h-full z-10'>
            <ResponsiveContainer width="95%" height={400}>
              <BarChart data={dynamicLineData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="month" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="count" fill="#783BFF" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>)
        : (
          <div className='fixed left-[28%] top-[28%] w-[50%] h-[68%] flex flex-col items-center justify-center bg-light-purple p-4 rounded-md shadow-md z-10'>
            <button onClick={() => setShowNoteHistory(false)}>Show Total Notes</button>
            <h2 className='text-[26px] mb-[2rem]'>Months Past: {doctor_name}</h2>
            <button className='absolute right-[20px] top-[10px] hover:text-ktx-navy font-bold text-[26px]' onClick={() => closeModal()}>x</button>
            <div>No Notes</div>
          </div>

        )}
    </>
  )
}