import { useState, useEffect } from "react";
import { getPatientsById, queryMonthlyUsageForDoctor, queryNoteCountForDoctor } from "../../../utils/functions";
import GraphAndDetails from "./GraphAndDetails";
import ChartAndDetails from "./ChartAndDetails";

export default function GetPatientsById({ doctor_id, doctor_name }) {
  const [patientCount, setPatientCount] = useState(null); // patientCount is the total number of patients for a doctor
  const [noteCount, setNoteCount] = useState(null); // noteCount is an object with total and recent keys for notes generated
  const [noteHistory, setNoteHistory] = useState(null); //noteHistory is an array of objects with month and count keys for notes generated 
  const [openModal, setOpenModal] = useState(false);

  const [showNoteHistory, setShowNoteHistory] = useState(false);

  const normalizedDoctorName = doctor_name.split(" ").join("");

  useEffect(() => {
    (async () => {
      setPatientCount(await getPatientsById(doctor_id))
    })()
  }, [])

  const closeModal = () => {
    setNoteCount(null);
    setNoteHistory(null);
    setOpenModal(false);
    setShowNoteHistory(false);
  }

  const updateNoteCount = async () => {
    setNoteCount(await queryNoteCountForDoctor(normalizedDoctorName));
    setNoteHistory(await queryMonthlyUsageForDoctor(normalizedDoctorName));
    setOpenModal(true);
  }

  return (
    <>
      {patientCount ? (
        <>
          <button onClick={updateNoteCount}>
            <span className="text-sage-green font-semibold relative">{patientCount}</span>
          </button>

          {openModal && <GraphAndDetails patientCount={patientCount} noteCount={noteCount} closeModal={closeModal} openModal={openModal} doctor_name={doctor_name} setShowNoteHistory={setShowNoteHistory} showNoteHistory={showNoteHistory} setNoteHistory={setNoteHistory} noteHistory={noteHistory} />}
          {openModal && showNoteHistory && <ChartAndDetails noteHistory={noteHistory} closeModal={closeModal} openModal={openModal} doctor_name={doctor_name} setShowNoteHistory={setShowNoteHistory} showNoteHistory={showNoteHistory} />}
        </>
      ) : (
        <span className="w-6 h-6 border-2 border-dashed rounded-full animate-spin border-ktx-purple mx-auto"></span>
      )}
    </>
  )
}