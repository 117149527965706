import React from "react";
import whiteLogo from "../../assets/White Knowtex Logo.png";
import { NavLink } from "react-router-dom";

export default function RegistrationSideBar() {
  return (
    <>
      <div className="hidden flex-col p-10 justify-center items-center h-dvh w-[50dvw] bg-register-bg sm:flex">
        <div className="w-[80%]">
          <NavLink to="/">
            <img
              className="mb-8 ml-[-2.7rem]"
              style={{ width: "12.5rem" }}
              src={whiteLogo}
              alt="White Logo"
            />
          </NavLink>
          <div className="flex flex-col">
            <h1 className="text-white font-extrabold text-3xl tracking-wider mb-6">
              Welcome to Knowtex!
            </h1>
          </div>
          <div className="flex flex-col text-[1rem] gap-[1rem] w-[80%] ml-[1.5rem]">
            <div className="relative flex items-start gap-[1rem] text-white">
              <svg
                className="h-4 w-4 absolute left-[-1.5rem] top-1 text-white dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M8.5 11.5 11 14l4-4m6 2a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
              </svg>
              <p>
                Let's onboard this new user!
              </p>
            </div>
            <div className="relative flex items-start gap-[1rem] text-white">
              <svg
                className="h-4 w-4 absolute left-[-1.5rem] top-1 text-white dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M8.5 11.5 11 14l4-4m6 2a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
              </svg>
              <p>
                So they can enjoy customized specialty-specific templates, automated coding
                suggestions, and real-time note turnaround.
              </p>
            </div>
            <div className="relative flex items-start gap-[1rem] text-white">
              <svg
                className="h-4 w-4 absolute left-[-1.5rem] top-1 text-white dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M8.5 11.5 11 14l4-4m6 2a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
              </svg>
              <p>
                Our proprietary private AI models offer multilingual and
                multimodal support, ensuring accurate documentation and
                streamlined reimbursement.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}