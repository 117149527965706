import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import { useState, useEffect } from 'react';

const COLORS = ["#7FB1BA", "#783BFF", "rgba(46, 133, 64, 1)", "rgba(1, 8, 90, 1)", "#6a34df"];

export default function PieChartDisplay({ asyncFunction }) {
  const [dynamicPieData, setDynamicPieData] = useState(null);

  useEffect(() => {
    if (asyncFunction) {
      (async() => {
        setDynamicPieData(await asyncFunction());
      })()
    }
  }, [])

  const customLegend = (value, entry) => {
    const { value: chartVal } = entry.payload;
    return `${value}: ${chartVal}`;
  }
  
  return (
    <>
      {asyncFunction && dynamicPieData ? (
        <ResponsiveContainer width={"96%"} height={400} >
          <PieChart>
            <Pie
              data={dynamicPieData}
              cx={"42%"}
              cy={"40%"}
              labelLine={false}
              outerRadius={160}
              fill="#8884d8"
              dataKey="value"
            >
              {dynamicPieData.map((_, i) => (
                <Cell key={`cell-${i}`} fill={COLORS[i % COLORS.length]} />
              ))}
            </Pie>
            <Tooltip />
            <Legend layout="vertical" align="right" iconType="circle" iconSize={10} verticalAlign='top' formatter={customLegend} />
          </PieChart>
        </ResponsiveContainer>
      ) : (
        <div className="w-full h-[400px] flex justify-center items-center">
          <div className="w-20 h-20 border-4 border-dashed rounded-full animate-spin-slow border-ktx-purple flex justify-center items-center"></div>
        </div>
      )}
    </>
  );
};

