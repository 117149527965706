import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { queryAllDatasets, queryAllModels } from "../../utils/fine-tuning-functions";
import SelectDatasets from "./SelectDatasets";
import UploadDatasets from "./UploadDatasets";
import CreateMIModel from "./CreateMIModel";
import DisplayModels from "./DisplayModels";

export default function FineTuning({ setAuth }) {
  const [datasets, setDatasets] = useState(null);
  const [models, setModels] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setDatasets(await queryAllDatasets());
      setModels(await queryAllModels());
    };

    fetchData();

    const intervalId = setInterval(fetchData, 5000); 

    return () => clearInterval(intervalId);
  }, [])
  
  return (
    <div className="flex flex-col justify-center items-center mx-auto relative text-ktx-navy">
      <NavLink to={"/"} className="absolute top-[1rem] left-[2rem] text-ktx-purple text-[20px] hover:text-ktx-navy font-semibold">Back</NavLink>
      <h1 className="font-bold text-[28px] text-ktx-purple my-[1rem]">
        AI Model Fine-Tuning
      </h1>
      <SelectDatasets datasets={datasets} setDatasets={setDatasets} />
      <UploadDatasets />
      <CreateMIModel />
      <DisplayModels models={models} />
    </div>
  )
}