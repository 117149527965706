import { useEffect, useState } from "react";
import Slider from "react-slick";
import { queryModel, deployModel, fineTuneModel, terminateModel } from "../../utils/fine-tuning-functions";
import ConfigureModelModal from "./ConfigureModelModal";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// filepath: /Users/knowtexnic/development/admin-dashboard/client/src/components/FineTuning/DisplayModels.js

export default function DisplayModels({ models }) {
  const [openConfigModal, setOpenConfigModal] = useState(false);
  const [selectedModel, setSelectedModel] = useState(null);
  const [groupedModels, setGroupedModels] = useState({});
  const [refinedModels, setRefinedModels] = useState([]);
  const [usedDatasets, setUsedDatasets] = useState([]);
  const [refinedUsedDatasets, setRefinedUsedDatasets] = useState([]);
  const [selectedDataset, setSelectedDataset] = useState(null);

  const handleOpenConfigModal = async (modelId) => {
    setOpenConfigModal(true);
    const data = await queryModel(modelId);
    setSelectedModel(data.model);
  };

  const handleCloseConfigModal = () => {
    setOpenConfigModal(false);
  };

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  useEffect(() => {
    if (models) {
      const grouped = models.reduce((acc, model) => {
        const status = model.training_status;
        if (!acc[status]) {
          acc[status] = [];
        }
        acc[status].push(model);
        return acc;
      }, {});
      setGroupedModels(grouped);
      const uniqueDatasets = models.map((model) => model.dataset_location).filter((value, index, self) => self.indexOf(value) === index);
      setUsedDatasets(uniqueDatasets);
    }
  }, [models]);

  useEffect(() => {
    if (selectedDataset) {
      const refined = models.filter((model) => model.dataset_location === selectedDataset);
      const grouped = refined.reduce((acc, model) => {
        const status = model.training_status;
        if (!acc[status]) {
          acc[status] = [];
        }
        acc[status].push(model);
        return acc;
      }, {});
      setRefinedModels(refined);
      setRefinedUsedDatasets(refined);
      setGroupedModels(grouped);
    } else { }
  }, [selectedDataset, models]);

  return (
    <>
      {models !== null ? (
        <div className="flex flex-col items-center w-full">
          <h2 className="font-bold text-[23px] text-ktx-purple my-[1rem]">
            Models
          </h2>
          <select className="mb-[1rem] w-1/2 p-2 border-2 border-ktx-purple rounded-md" onChange={(e) => setSelectedDataset(e.target.value)}>
            <option value="" selected>Select a dataset</option>
            {usedDatasets.map((dataset, index) => (
              <option key={index} value={dataset}>{dataset}</option>
            ))}
          </select>
          <Slider {...settings} className="w-full max-w-[80%]">
            {Object.keys(groupedModels).map((status) => (
              <div key={status} className="w-full">
                <h3 className="font-bold text-[20px] text-ktx-purple mb-4 text-center">
                  {status}
                </h3>
                <div className="flex flex-col items-center">
                  {groupedModels[status].map((model, index) => (
                    <div key={index} className="p-2 w-full">
                      <div className="bg-ktx-purple text-white p-4 rounded-md shadow-knowtex-shadow gap-1 flex flex-col m-2">
                        <h3 className="font-bold text-lg">{model.name}</h3>
                        <h4 className="bg-barely-purple rounded-md w-fit py-2 px-4 text-ktx-purple">{model.deployment_health_check}</h4>
                        <p className="text-sm">{model.description ? model.description : "No description"}</p>
                        <p className="text-sm">Training Status: {model.training_status}</p>
                        <p className="text-sm">Model Type: {model.model_type}</p>
                        <p className="text-sm">Dataset Location: {model.dataset_location ? model.dataset_location : "Not Configured"}</p>
                        <p className="text-sm">Hugging Face Model Name: {model.huggingface_model_name ? model.huggingface_model_name : "No Huggingface Model Yet"}</p>
                        <p className="text-sm">Deployment URL: {model.deployment_url ? model.deployment_url : "Not yet deployed"}</p>
                        {model.training_status === "CONFIGURED" && (
                          <button onClick={() => fineTuneModel(model.id)}>Fine-Tune</button>)}
                        <div className="flex gap-4 justify-between my-4">
                          <button onClick={() => handleOpenConfigModal(model.id)} className="bg-white hover:bg-ktx-navy text-ktx-purple p-2 rounded-md w-20% mx-auto hover:text-white min-w-[100px]">Configure</button>
                          {model.instance_id && model.deployment_url &&
                            <button className="bg-ktx-red hover:bg-red-700 text-white p-2 rounded-md w-20% mx-auto min-w-[100px]">Terminate</button>
                          }
                        </div>
                        {model.training_status === "COMPLETED" && (
                          <button onClick={() => deployModel(model.id)} className="bg-sage-green hover:bg-ktx-green text-white p-2 rounded-md w-20% mx-auto min-w-[100px]">Deploy</button>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </Slider>
          {openConfigModal && <ConfigureModelModal model={selectedModel} closeModal={handleCloseConfigModal} />}
        </div>
      ) : (
        <div className="flex flex-col items-center">
          <h2 className="font-bold text-[23px] text-ktx-purple my-[2rem]">
            Models
          </h2>
          <span className="w-6 h-6 border-2 border-dashed rounded-full animate-spin border-ktx-purple mx-auto"></span>
        </div>
      )}
    </>
  );
}