export default function CurrentMonthLeaderboard({ leaderboardData, org, setShowLeaderboard, totalDoctors }) {
  
  return (
    <>
      {leaderboardData !== null ? (
        <div className="fixed bg-barely-purple w-[60%] max-h-[500px] flex flex-col rounded-md">
          <h2 className="text-[26px] font-semibold text-center bg-light-purple rounded-t-md py-4">Leaderboard: {org}</h2>
          <h3 className="text-[18px] font-semibold text-center bg-light-purple">Clinician Activation: {leaderboardData.length}/{totalDoctors}</h3>
          <button className="text-ktx-purple hover:text-ktx-navy bg-light-purple" onClick={() => setShowLeaderboard("weekly")}>Show Weekly Leaderboard</button>
        <button className="absolute top-[-5px] right-[5px] text-[26px] p-2 z-10" onClick={() => setShowLeaderboard("")}>×</button>
        <div className="overflow-y-auto flex-1">
          <table className="w-full">
            <thead className="sticky top-0 bg-light-purple">
              <tr className="text-left">
                <th className="py-2 px-4">Rank</th>
                <th className="py-2 px-4">Doctor</th>
                <th className="py-2 px-4">Email</th>
                <th className="py-2 px-4">Notes</th>
              </tr>
            </thead>
            <tbody>
              {leaderboardData.map((row, index) => (
                <tr key={index} className="border-b">
                  <td className="py-2 px-4">{index + 1}</td>
                  <td className="py-2 px-4">{row.doctorName.replace(".", ". ")}</td>
                  <td className="py-2 px-4">{row.doctorEmail}</td>
                  <td className="py-2 px-4">{row.notes}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>) : (
        <div className='fixed left-[28%] top-[28%] w-[50%] h-[68%] flex flex-col items-center justify-center bg-light-purple p-4 rounded-md shadow-md z-10'>
          <span className="w-6 h-6 border-2 border-dashed rounded-full animate-spin border-ktx-purple mx-auto"></span>
        </div>
      )
      }
    </>
  )
}