import { useState, useEffect } from "react";

export default function PLGUsersList({ helperData, setOpenModal }) {
  const [copied, setCopied] = useState("");

  const handleCloseModal = () => {
    setOpenModal("");
  }

  const copyToClipboard = async (e) => {
    navigator.clipboard.writeText(e.target.innerText);

    setCopied(`Successfully copied: ${e.target.innerText}`);

    setTimeout(() => {
      setCopied("");
    }, 1000)
  }

  return (
    <div className="bg-white fixed top-[120px] w-[60%] h-[400px] z-10 right-[20%] flex flex-col gap-4 p-[1rem] shadow-knowtex-shadow rounded-md overflow-y-auto">
      <button className='absolute right-[20px] top-[10px] hover:text-ktx-navy font-bold text-[26px]' onClick={handleCloseModal}>×</button>
      <h2 className='w-full text-center text-[26px]'>PLG Users:</h2>
      {copied && <span className='fixed w-[80%] h-auto right-[10%] top-[55px] text-center text-ktx-purple bg-white shadow-knowtex-shadow rounded-md p-3 z-20'>{copied}</span>}
      {helperData !== null ? (
        <>
          {helperData.map((el, i) => (
            <span key={i}>{i + 1}. Name: {el.user_name} || Subscribed?: {el.subscribed === null || !el.subscribed ? "No" : "Yes"} || Signed Up: {new Date(el.created_at).toLocaleDateString('en-US', {
                month: '2-digit',
                day: '2-digit',
                year: 'numeric'
              })} || Email:{" "}
              <button onClick={copyToClipboard}>{el.user_email}</button>
            </span>
          ))}
        </>
      ) : (
        <span className="w-6 h-6 border-2 border-dashed rounded-full animate-spin border-ktx-purple mx-auto"></span>
      )}
    </div>
  )
}