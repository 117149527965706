import React from "react";
import SignUpForm from "./CognitoReactSignUpForm";

const Register = () => {
  return (
    <div>
      <SignUpForm />
    </div>
  );
};
export default Register;
