/**
 * The Review component in React handles audio playback, text parsing, note editing, and uploading
 * medical information to DrChrono, with features like word highlighting, note saving, and QA review
 * tracking.
 * @returns The Review component is being returned. It contains various states for managing the review
 * process of medical transcripts. The component includes audio playback functionality, text parsing,
 * updating notes, saving drafts, uploading notes to DrChrono, and handling different fields related to
 * medical information such as history of present illness, imaging, assessment, MRI/CT details,
 * follow-up, etc. The component also includes UI elements for displaying
 */
import React, { useRef, useState, useEffect } from "react";
import Player from "../Player/Player";
import Sidebar from "../Sidebar";
import { getMRNByPatientId, formatChartData } from "../../utils/functions";

const Review = ({ setAuth }) => {
  const [isplaying, setisplaying] = useState(false);
  const [currentAudio, setCurrentAudio] = useState("");
  const [jobName, setJobName] = useState("");
  const [OriginalTranscript, setOriginalTranscript] = useState("");
  const [patientsChange, setPatientsChange] = useState(false);
  const [patient, setPatient] = useState("");
  const [note, setNote] = useState("");
  const [aiNote, setaiNote] = useState("");
  const [QANote, setQANote] = useState("");
  const [codes, setCodes] = useState("");
  const [patientName, setPatientName] = useState("");
  const [reviewerId, setReviewerId] = useState(null);
  const [appointmentId, setAppointmentId] = useState(null);
  const [mrn, setMrn] = useState("");
  const [chartHistory, setChartHistory] = useState("");

  const [isStatus, setIsStatus] = useState(false);
  const [isQAReviewed, setIsQAReviewed] = useState(false);

  const [duration, setDuration] = useState("");
  const [currTime, setCurrTime] = useState("");
  const [originalTranscriptWords, setOriginalTranscriptWords] = useState([]);
  const [doctorDidReview, setDoctorDidReview] = useState("");
  const [selectedSentence, setSelectedSentence] = useState([]);
  const [currentlyReviewing, setCurrentlyReviewing] = useState([]);
  const [misspelledWords, setMisspelledWords] = useState([]);
  const [status, setStatus] = useState("");
  const [refresh, setRefresh] = useState(false);

  const audioElem = useRef();

  const [historyPresentIllness, setHistoryPresentIllness] = useState("");
  const [physicalExam, setPhysicalExam] = useState("");
  const [imaging, setImaging] = useState("");
  const [assessment, setAssessment] = useState("");
  const [mriCt, setMriCt] = useState("");
  const [mriCtFreeText, setMriCtFreeText] = useState("");
  const [followUp, setFollowUp] = useState("");

  function extractMedicalInfo(text) {
    const hpiStartIndex = text.indexOf("History of Present Illness");
    const hpiEndIndex = text.indexOf("Physical Exam");
    const hpi = text
      .slice(hpiStartIndex, hpiEndIndex)
      .replace("History of Present Illness", "")
      .replace("--------------------------", "")
      .trim();

    const imagingStartIndex = text.indexOf("Imaging");
    const imagingEndIndex = text.indexOf("Assessment/Plan");
    const imaging = text
      .slice(imagingStartIndex, imagingEndIndex)
      .replace("Imaging", "")
      .replace("-------", "")
      .trim();

    const assessmentStartIndex = text.indexOf("Assessment/Plan");
    const assessment = text
      .slice(assessmentStartIndex)
      .replace("Assessment/Plan", "")
      .replace("----------------", "")
      .trim();

    return { hpi, assessment, imaging };
  }

  const handleUploadDrChrono = async () => {
    try {
      const appointment_id = appointmentId; // Replace with the actual appointment ID

      const requestBody = {
        appointment_id: appointment_id,
        assessment,
        hpi: historyPresentIllness,
        imaging,
        mriCt,
        mriCtFreeText,
        followUp,
      };

      const response = await fetch(
        `https://3zywrqera3.us-east-1.awsapprunner.com/drchrono/record`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (response.ok) {
        // Successful upload
        console.log("Data uploaded successfully to DrChrono");
        alert("Uploaded to DrChrono");
      } else {
        // Handle error
        console.error(
          "Error uploading data:",
          response.status,
          response.statusText
        );
        alert("Error uploading data");
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  //submits updated note to Postgres DB
  const editText = async (id) => {
    try {
      const status = "Review";
      const body = { note, status };

      const myHeaders = new Headers();

      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("token", localStorage.token);
      //console.log(localStorage.token);

      await fetch(
        `${process.env.REACT_APP_API_URL}/reviews/${id}`,
        {
          method: "PUT",
          headers: myHeaders,
          body: JSON.stringify(body),
        }
      );
      setPatientsChange(true);
      setIsStatus(true);

      //window.location = "/";
    } catch (err) {
      console.error(err.message);
    }

    // Now update the dynamodb
    updateDynamodbJobStatus();
  };

  //saves updated note to Postgres DB BUT DOES NOT CHANGE STATUS
  const saveText = async (id) => {
    try {
      const body = { note };

      const myHeaders = new Headers();

      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("token", localStorage.token);

      await fetch(
        `${process.env.REACT_APP_API_URL}/reviews/save/${id}`,
        {
          method: "PUT",
          headers: myHeaders,
          body: JSON.stringify(body),
        }
      );
      setPatientsChange(true);

      //window.location = "/";
    } catch (err) {
      console.error(err.message);
    }
  };

  //retrieves SOAP note from Postgres DB
  async function getNote(id) {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/reviews/patient/${id}`,
        {
          method: "GET",
        }
      );

      const parseRes = await response.json();
      setNote(parseRes[0].note);
      setPatientName(parseRes[0].description);
      setAppointmentId(parseRes[0].appointment_id);
      setStatus(parseRes[0].status);
      setMrn(parseRes[0].mrn);

      if (parseRes[0].chart_history) {
        setChartHistory(formatChartData(parseRes[0].chart_history));
      }
    } catch (err) {
      console.error(err.message);
    }
  }

  async function updateNoteStatus(id) {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/reviews/patient/${id}`,
        {
          method: "GET",
        });

      const parseRes = await res.json();
      setStatus(parseRes[0].status);
    } catch (err) {
      console.error(err.message);
    }
  }

  // Update the job name status scribed status to true
  async function updateDynamodbJobStatus() {
    var reviewer = window.localStorage.getItem("username");
    var getTask_url = `${process.env.REACT_APP_DYNAMO_API_URL}/Prod/updateSummaryJob?summarize_job_name=${jobName}&is_reviewed=true&reviewer_id=${reviewer}`;

    postData(getTask_url).then((data) => {
      setIsQAReviewed(true);
      //console.log("Response of setting job name:%s to true :%v", jobName, data);
      setReviewerId(reviewer);
    });
  }

  //console.log("current audio: %v \n",currentAudio);

  async function postData(url = "", data = {}, method = "GET") {
    // Default options are marked with *
    const response = await fetch(url, {
      method: method, // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      // Add the 'token' as a header to allow access to backend
      headers: {
        token: window.localStorage.getItem("token"),
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      //body: JSON.stringify(data) // body data type must match "Content-Type" header
    });
    return response.json(); // parses JSON response into native JavaScript objects
  }

  const handleJobChange = (event) => {
    setJobName(event.target.value);
  };

  const parseDrChronoFields = () => {
    const extracted_data = extractMedicalInfo(note);
    setHistoryPresentIllness(extracted_data.hpi);
    setAssessment(extracted_data.assessment);
    setImaging(extracted_data.imaging);
  };

  // function to fetch the job info given by the given job name
  const fetchJobInfo = (event) => {
    //clear prefill fields
    // console.log(event);
    setHistoryPresentIllness("");
    setAssessment("");
    setImaging("");
    setMriCt("");
    setMriCtFreeText("");
    setFollowUp("");
    var getTask_url = `${process.env.REACT_APP_DYNAMO_API_URL}/Prod/summarizejob?summarize_job_name=${jobName}`;
    postData(getTask_url).then((data) => {
      // console.log(getTask_url);
      // console.log("Got response:%v", data);
      // set the original task info
      // setTasks(data);
      // // set the reviewed words by the original words
      // setReviewedWords(data.original_words);
      // update the url
      https: setCurrentAudio({ title: data.jobName, url: data.audio_url });
      // update the original transcript
      setOriginalTranscript(data.original_transcript);
      //set ai created note
      setaiNote(data.soap_note);
      //set if doctor has viewed note
      setDoctorDidReview(data.doctor_clicked_review);
      //set cpt codes
      setCodes(data.cpt_codes);
      //get patient and note from postgres
      // setPatientName(data.patient_id);
      // just to ensure any pre-existing logic is still in place
      setPatient(data.patient_id);
      //get appointment id from postgres
      setAppointmentId(data.appointment_id);

      getNote(data.patient_id);
      setOriginalTranscriptWords(data.original_transcript_words);
      setQANote(data.llm_reviewed_soap_note);
      //set reviewer id
      setReviewerId(data.reviewer_id);
      setMisspelledWords(data.soap_note_wrong_words);
      // // generate url
      // var limit_time_url = `${data.audio_url}#=${data.start_time},${data.end_time}`;
      // console.log("Genereted new url:%v\n", limit_time_url);
      // // update the current playing audio
      // setCurrentAudio({ title: data.jobName, url: limit_time_url });
      // // set the playtime as the current start time
      // audioElem.current.currentTime = parseInt(data.start_time);
      // console.log("Set current time to: %v", audioElem.current.currentTime);
    });

    // var getReviewerURL = `https://mmedsee030.execute-api.us-east-1.amazonaws.com/Prod/updateSummaryJob?summarize_job_name=${jobName}&is_reviewed=false&reviewer_id=${reviewerId}`;
    // postData(getReviewerURL).then((data) => {
    //   console.log(data);
    //   setCurrentlyReviewing(reviewerId);
    // });
  };

  const handleWordClick = (start, index) => {
    audioElem.current.currentTime = start;
    audioElem.current.play();
    setisplaying(true);
    setSelectedSentence(index);
  };

  useEffect(() => {
    const handleTimeUpdate = () => {
      const currentTime = audioElem.current.currentTime;

      // Find the index of the word that matches the current time
      const currentWordIndex = originalTranscriptWords.findIndex(
        (word, index) =>
          currentTime >= word.start &&
          (index === originalTranscriptWords.length - 1 ||
            currentTime < originalTranscriptWords[index + 1].start)
      );

      if (currentWordIndex !== -1) {
        setSelectedSentence(currentWordIndex);
      }
    };

    if (audioElem.current) {
      audioElem.current.addEventListener("timeupdate", handleTimeUpdate);
    }

    // Cleanup event listener on component unmount
    return () => {
      if (audioElem.current) {
        audioElem.current.removeEventListener("timeupdate", handleTimeUpdate);
      }
    };
  }, [originalTranscriptWords]);

  useEffect(() => {
    //console.log("Current playing time :%v, duration:%v \n",audioElem.current.currentTime,audioElem.current.duration);
    if (isplaying) {
      audioElem.current.play();
    } else {
      audioElem.current.pause();
    }
  }, [isplaying]);

  const onPlaying = () => {
    setDuration(audioElem.current.duration);
    setCurrTime(audioElem.current.currentTime);
    //console.log("Current audio info:%v \n", currTime);
    // console.log("duration:%v \n", duration);
  };

  useEffect(() => {
    if (jobName) {
      const interval = setInterval(async () => {
        const url = `${process.env.REACT_APP_DYNAMO_API_URL}/Prod/summarizejob?summarize_job_name=${jobName}`;

        try {
          const data = await postData(url);

          setDoctorDidReview(data.doctor_clicked_review);
          updateNoteStatus(data.patient_id);
          setRefresh(!refresh);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }, 5000);

      return () => clearInterval(interval);
    }
  }, [jobName, refresh]);

  return (
    <div className="flex">
      <div className="h-[100dvh]">
        <div className="flex h-[100%] fixed top-0">
          <Sidebar setAuth={setAuth} />
        </div>
      </div>
      <div className="ml-[369px] flex overflow-x-hidden">
        <div className="flex flex-col">
          <div className="flex flex-col justify-center items-center w-[78dvw]">
            <h1 className="font-bold text-[28px] text-ktx-purple mt-[2rem]">
              Review Note
            </h1>
            <div className="w-[100%] items-center ml-[15%]">
              <audio
                src={currentAudio.url}
                ref={audioElem}
                onTimeUpdate={onPlaying}
              />
              <Player
                isplaying={isplaying}
                setisplaying={setisplaying}
                audioElem={audioElem}
                currentAudio={currentAudio}
                setCurrentAudio={setCurrentAudio}
                duration={duration}
                currTime={currTime}
              />
            </div>
            <div className="flex w-[85%] z-10 mt-[1.7rem]">
              <div className="flex rounded-md bg-white shadow-knowtex-shadow w-[80%] h-[2.5rem]">
                <svg
                  className="self-center ml-[.4rem] w-6 h-6 text-ktx-navy dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M8 7H5a2 2 0 0 0-2 2v4m5-6h8M8 7V5a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2m0 0h3a2 2 0 0 1 2 2v4m0 0v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-6m18 0s-4 2-9 2-9-2-9-2m9-2h.01"
                  />
                </svg>
                <input
                  type="job"
                  placeholder="Job"
                  name="job"
                  required={true}
                  className="text-ktx-navy p-[.5rem] w-[100%] italic border-none bg-none rounded-md self-center"
                  value={jobName}
                  onChange={handleJobChange}
                />
              </div>
              <button
                className="disabled:bg-transparent-grey font-semibold hover:bg-ktx-navy rounded-md bg-ktx-purple ml-[1.7rem] tracking-widest text-white py-[.7rem] h-[2.5rem] px-[3rem] w-[20%] items-center flex justify-center"
                onClick={fetchJobInfo}
                disabled={!jobName.startsWith("Summarize")}
              >
                Get Job
              </button>
            </div>

            {/* Second row of fields */}

            <div className="flex w-[85%] justify-evenly gap-x-[1.25rem]">
              <div className="flex rounded-md bg-white  w-[25%] h-[2.5rem]">
                <svg
                  className="self-center ml-[.4rem] w-6 h-6 text-ktx-navy dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M15 9h3m-3 3h3m-3 3h3m-6 1c-.306-.613-.933-1-1.618-1H7.618c-.685 0-1.312.387-1.618 1M4 5h16a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1Zm7 5a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z"
                  />
                </svg>
                <a
                  type="text"
                  className="text-ktx-navy w-[100%] border-none bg-none self-center rounded-md"
                >
                  Patient: {patientName}
                </a>
              </div>
              <div className="flex rounded-md bg-white w-[25%] h-[2.5rem]">
                <svg className="self-center ml-[.4rem] w-6 h-6 text-ktx-navy dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 6c0 1.657-3.134 3-7 3S5 7.657 5 6m14 0c0-1.657-3.134-3-7-3S5 4.343 5 6m14 0v6M5 6v6m0 0c0 1.657 3.134 3 7 3s7-1.343 7-3M5 12v6c0 1.657 3.134 3 7 3s7-1.343 7-3v-6" />
                </svg>

                <a
                  type="text"
                  className="text-ktx-navy w-[100%] border-none bg-none self-center rounded-md"
                >
                  MRN: {mrn}
                </a>
              </div>
              <div className="flex rounded-md bg-white  w-[25%] h-[2.5rem]">
                <svg
                  className="self-center ml-[.4rem] w-6 h-6 text-ktx-navy dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    strokeWidth="2"
                    d="M7 17v1a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1a3 3 0 0 0-3-3h-4a3 3 0 0 0-3 3Zm8-9a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                  />
                </svg>
                <a
                  type="text"
                  className="text-ktx-navy w-[100%] border-none bg-none self-center rounded-md"
                >
                  Reviewer: {reviewerId}
                </a>
              </div>
              <div className="flex rounded-md bg-white  w-[25%] h-[2.5rem]">
                <svg
                  className="self-center ml-[.4rem] w-6 h-6 text-ktx-navy dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 12a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1M5 12h14M5 12a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1m-2 3h.01M14 15h.01M17 9h.01M14 9h.01"
                  />
                </svg>
                <a
                  type="text"
                  className="text-ktx-navy w-[100%] border-none bg-none self-center rounded-md"
                >
                  Status: {status}
                </a>
              </div>
              <div className="flex rounded-md bg-white w-[25%] h-[2.5rem]">
                <svg
                  className="self-center ml-[.4rem] w-6 h-6 text-ktx-navy dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 8h6m-6 4h6m-6 4h6M6 3v18l2-2 2 2 2-2 2 2 2-2 2 2V3l-2 2-2-2-2 2-2-2-2 2-2-2Z"
                  />
                </svg>
                <a
                  type="text"
                  className="text-ktx-navy w-[100%] border-none bg-none self-center rounded-md"
                >
                  Did Review: {String(doctorDidReview)}
                </a>
              </div>
            </div>
          </div>

          {/* First 3 fields Transcript, AI Note, and Final Note */}
          <div className="flex flex-col justify-center items-center">
            <div className="flex mt-[1rem] w-[85%] max-w-[1200px] shadow-knowtex-shadow rounded-md">
              <div className="flex flex-col w-[33.33%]">
                <h3 className="p-[.25rem] text-[1.25rem] text-white bg-dark-purple rounded-tl-md">
                  Transcript
                </h3>
                <div
                  id="transcript"
                  name="transcript"
                  className="text-[.55rem] border-none resize-none h-[30rem] py-[.25rem] bg-barely-purple text-ktx-navy rounded-bl-md overflow-auto break-words"
                >
                  {originalTranscriptWords.map((word, index) => (
                    <span
                      key={index}
                      onClick={() => handleWordClick(word.start, index)}
                      className={`cursor-pointer ${selectedSentence === index ? "bg-yellow-200" : ""
                        }`}
                    >
                      {word.text}{" "}
                    </span>
                  ))}
                </div>
              </div>

              <div className="flex flex-col w-[33.33%]">
                <h3 className="p-[.25rem] text-[1.25rem] text-white bg-dark-purple">
                  AI Note
                </h3>
                <textarea
                  id="ai-note"
                  name="note"
                  className="text-[.75rem] border-none resize-none h-[30rem] py-[.25rem] bg-barely-purple text-ktx-navy"
                  defaultValue={aiNote}
                />
              </div>
              <div className="flex flex-col w-[33.33%]">
                <h3 className="p-[.25rem] text-[1.25rem] text-white bg-dark-purple rounded-tr-md">
                  Final Note
                </h3>
                <textarea
                  id="final-note"
                  name="note"
                  className="text-[.75rem] border-none resize-none h-[30rem] py-[.25rem] bg-barely-purple text-ktx-navy rounded-br-md"
                  defaultValue={note}
                  onChange={(e) => setNote(e.target.value)}
                />
              </div>
            </div>
          </div>

          {/* History of present illness and assessment */}

          <div className="flex flex-col justify-center items-center">
            <div className="flex mt-[1rem] w-[85%] shadow-knowtex-shadow rounded-md">
              <div className="flex flex-col w-[50%]">
                <h3 className="p-[.25rem] text-[1.25rem] text-white bg-dark-purple rounded-tl-md">
                  HPI
                </h3>
                <textarea
                  id="historyPresentIllness"
                  value={historyPresentIllness}
                  onChange={(e) => setHistoryPresentIllness(e.target.value)}
                  className="border-none resize-none h-[9rem] py-[1rem] bg-barely-purple text-ktx-navy rounded-bl-md"
                />
              </div>
              <div className="flex flex-col w-[50%]">
                <h3 className="p-[.25rem] text-[1.25rem] text-white bg-dark-purple rounded-tr-md">
                  Assessment
                </h3>
                <textarea
                  id="assessment"
                  value={assessment}
                  onChange={(e) => setAssessment(e.target.value)}
                  className="border-none resize-none h-[9rem] py-[1rem] bg-barely-purple text-ktx-navy rounded-br-md"
                />
              </div>
            </div>
          </div>

          {/* smaller and final components */}

          <div className="flex flex-col justify-center items-center ">
            <div className="flex mt-[1rem] w-[85%] justify-evenly gap-x-[1.25rem]">
              <div className="flex rounded-md bg-white shadow-knowtex-shadow w-[25%] h-[2.5rem]">
                <svg
                  className="self-center ml-[.4rem] w-6 h-6 text-ktx-navy dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M14 6H4a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1Zm7 11-6-2V9l6-2v10Z"
                  />
                </svg>
                <input
                  type="text"
                  placeholder="X-Ray/Fluoroscopy"
                  className="text-ktx-navy w-[100%] italic border-none bg-none self-center rounded-md"
                  value={imaging}
                  onChange={(e) => setImaging(e.target.value)}
                />
              </div>
              <div className="flex rounded-md bg-white shadow-knowtex-shadow w-[25%] h-[2.5rem]">
                <svg
                  className="self-center ml-[.4rem] w-6 h-6 text-ktx-navy dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M10 3v4a1 1 0 0 1-1 1H5m14-4v16a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V7.914a1 1 0 0 1 .293-.707l3.914-3.914A1 1 0 0 1 9.914 3H18a1 1 0 0 1 1 1ZM9 12h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1v-2a1 1 0 0 1 1-1Zm5.697 2.395v-.733l1.269-1.219v2.984l-1.268-1.032Z"
                  />
                </svg>
                <select
                  className="text-ktx-navy w-[100%] italic border-none bg-none self-center rounded-md"
                  value={mriCt}
                  onChange={(e) => setMriCt(e.target.value)}
                >
                  <option value="" disabled>
                    MRI/CT
                  </option>
                  <option value="IASM">IASM</option>
                  <option value="RadNet">RadNet</option>
                  <option value="SimonMed">SimonMed</option>
                  <option value="Other Facility">Other Facility</option>
                  <option value="Unknown Location">Unknown Location</option>
                </select>
              </div>
              <div className="flex rounded-md bg-white shadow-knowtex-shadow w-[25%] h-[2.5rem]">
                <svg
                  className="self-center ml-[.4rem] w-6 h-6 text-ktx-navy dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M16 18H8l2.5-6 2 4 1.5-2 2 4Zm-1-8.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Z"
                  />
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M10 3v4a1 1 0 0 1-1 1H5m14-4v16a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V7.914a1 1 0 0 1 .293-.707l3.914-3.914A1 1 0 0 1 9.914 3H18a1 1 0 0 1 1 1ZM8 18h8l-2-4-1.5 2-2-4L8 18Zm7-8.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Z"
                  />
                </svg>
                <input
                  type="text"
                  placeholder="MRI/CT Free Text"
                  className="text-ktx-navy w-[100%] italic border-none bg-none self-center rounded-md"
                  value={mriCtFreeText}
                  onChange={(e) => setMriCtFreeText(e.target.value)}
                />
              </div>
              <div className="flex rounded-md bg-white shadow-knowtex-shadow w-[25%] h-[2.5rem]">
                <svg
                  className="self-center ml-[.4rem] w-6 h-6 text-ktx-navy dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeWidth="2"
                    d="M16 19h4a1 1 0 0 0 1-1v-1a3 3 0 0 0-3-3h-2m-2.236-4a3 3 0 1 0 0-4M3 18v-1a3 3 0 0 1 3-3h4a3 3 0 0 1 3 3v1a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1Zm8-10a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                  />
                </svg>

                <select
                  className="text-ktx-navy w-[100%] italic border-none bg-none self-center rounded-md"
                  value={followUp}
                  onChange={(e) => setFollowUp(e.target.value)}
                >
                  <option value="" disabled>
                    Follow-up
                  </option>
                  <option value="1 Week">1 week</option>
                  <option value="10 Days">10 days</option>
                  <option value="2 Weeks">2 weeks</option>
                  <option value="3 Weeks">3 weeks</option>
                  <option value="4 Weeks">4 weeks</option>
                  <option value="5 Weeks">5 weeks</option>
                  <option value="6 Weeks">6 weeks</option>
                  <option value="2 Months">2 months</option>
                  <option value="3 Months">3 months</option>
                  <option value="4 Months">4 months</option>
                  <option value="5 Months">5 months</option>
                  <option value="6 Months">6 months</option>
                  <option value="Surgery">Surgery</option>
                  <option value="As Needed">As Needed</option>
                </select>
              </div>
            </div>
          </div>

          <div className="flex flex-col justify-center items-center">
            <div className="flex mt-[1rem] w-[85%] shadow-knowtex-shadow rounded-md">
              <div className="flex flex-col w-full p-8]">
                <h3 className="p-[.25rem] text-[1.25rem] text-white bg-dark-purple rounded-t-md">
                  Chart History
                </h3>
                <textarea
                  id="chart-history"
                  defaultValue={!patient ? "" : chartHistory ? chartHistory : "No chart history available"}
                  className={!chartHistory ? `border-none resize-none h-[9rem] py-[1rem] bg-barely-purple text-ktx-navy rounded-bl-md` : `border-none resize-none h-[30rem] py-[1rem] bg-barely-purple text-ktx-navy rounded-bl-md`}
                />
              </div>
            </div>
          </div>

          <div className="my-[2rem] flex justify-end w-[92.5%] gap-[1.7rem]">
            <button
              className="hover:text-ktx-navy text-ktx-purple underline font-semibold"
              type="button"
              onClick={parseDrChronoFields}
            >
              Parse DrChrono Fields
            </button>
            <button
              className="hover:text-ktx-navy text-ktx-purple underline font-semibold"
              type="button"
              onClick={() => {
                saveText(patient);
                updateDynamodbJobStatus();
              }}
            >
              QA Reviewed
            </button>
            <button
              className="hover:text-ktx-navy text-ktx-purple underline font-semibold"
              type="button"
              onClick={() => saveText(patient)}
            >
              Save Draft
            </button>

            <button
              type="button"
              onClick={handleUploadDrChrono}
              className="hover:bg-ktx-purple hover:text-white rounded-md border-[1px] border-ktx-purple text-ktx-purple py-[.7rem] h-[2.5rem] px-[2rem] w-auto items-center flex justify-center font-semibold"
            >
              Upload to DrChrono
            </button>

            <button
              type="button"
              onClick={() => editText(patient)}
              className="font-semibold hover:bg-ktx-navy rounded-md bg-ktx-purple text-white py-[.7rem] h-[2.5rem] px-[2rem] w-auto items-center flex justify-center tracking-widest"
            >
              Upload Note
            </button>

            {isStatus && <div>Note Uploaded</div>}
            {isQAReviewed && <div>QA Reviewed</div>}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Review;
