import React, { useState, useEffect } from "react";
import Sidebar from "./Sidebar";
import RightLinkBar from "./HomeWidgets/RightLinkBar";
import HomeWidget from "./HomeWidgets/HomeWidget";
import JokeWidget from "./HomeWidgets/JokeWidget";
import LineGraph from "./HomeWidgets/LineGraph";
import PieChartDisplay from "./HomeWidgets/PieChart";
import { getTotalNotes, getActiveDoctors, getActiveVsTotal, getAllDoctorsCount, getAllPatientsCount, getAllPlgUsers, getAllSpecialties, renderSixMonthSpan, getAverageSummarizeTime, getAverageTranscribeTime, getTotalMobileNotes, getTotalWebappNotes, getNotesGenerated, getTotalApiCalls, getAllOrgs, queryNotesProcessing, queryAllDoctors } from "../utils/functions";
import OrgSelector from "./HomeWidgets/OrgSelector";
import DoctorSelector from "./HomeWidgets/DoctorSelector";

const Home = ({ setAuth }) => {
  const username = localStorage.getItem("username");
  const todaysDate = new Date().toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });

  const activeDoctorTimeline = renderSixMonthSpan();

  const [showJoke, setShowJoke] = useState(false);

  return (
    <div>
      <Sidebar setAuth={setAuth} />
      <RightLinkBar />
      <div className="h-dvh flex flex-col ml-[369px] mr-[280px] text-dark-purple">
        <div className="flex flex-col items ml-[15%] mt-[10%] gap-6">
          <div>
            <h1 className="font-black text-[36px] relative">Welcome back, {username}! <button onClick={(() => setShowJoke(!showJoke))}>👋</button>
              <JokeWidget showJoke={showJoke} />
            </h1>
            <h3 className="text-[20px]">{todaysDate}</h3>
          </div>
          <div className="flex flex-col gap-3">
            <h3 className="font-extrabold text-[24px]">At a Glance 🔎</h3>
            <div className="flex gap-4 flex-wrap">
              <HomeWidget displayText="Doctors" asyncFunction={getAllDoctorsCount} />
              <HomeWidget displayText="Active Doctors" asyncFunction={getActiveDoctors} />
              <HomeWidget displayText="Patients" asyncFunction={getAllPatientsCount} />
              <HomeWidget displayText="PLG Users" asyncFunction={getAllPlgUsers} helperScript={"PLG Users"} />
              <HomeWidget displayText="Notes" asyncFunction={getTotalNotes} />
              {/* <HomeWidget displayText="iOS Errors" variableProp={totalIOSErrors} /> */}
              <HomeWidget displayText="Turnaround" asyncFunction={getAverageTranscribeTime} appendS={true} />
              {/* <HomeWidget displayText="Android Errors" variableProp={totalAndroidErrors} /> */}
              {/* <HomeWidget displayText="API Calls" variableProp={APICalls} /> */}
              <HomeWidget displayText="Processing" asyncFunction={queryNotesProcessing} helperScript={"Processing"} />
              <HomeWidget displayText="Summarize Time" asyncFunction={getAverageSummarizeTime} appendS={true} />
              <HomeWidget displayText="Mobile Notes" asyncFunction={getTotalMobileNotes} />
              <HomeWidget displayText="Web App Notes" asyncFunction={getTotalWebappNotes} />
            </div>
          </div>
          <div className="flex flex-col gap-3">
            <h3 className="font-extrabold text-[24px]">Number of Doctors</h3>
            <h6>Date Range: {activeDoctorTimeline}</h6>
            <div className="flex gap-4 flex-wrap w-[90%]">
              <LineGraph asyncFunction={getActiveVsTotal} />
            </div>
          </div>
          <div className="flex flex-col gap-3">
            <h3 className="font-extrabold text-[24px]">Specialties</h3>
            <div className="flex gap-4 flex-wrap w-[90%]">
              <PieChartDisplay asyncFunction={getAllSpecialties} />
            </div>
          </div>
          <div className="flex flex-col gap-3">
            <h3 className="font-extrabold text-[24px]">Organizations</h3>
            <div className="flex gap-4 flex-wrap w-[90%]">
              <OrgSelector asyncFunction={getAllOrgs} />
            </div>
          </div>
          <div className="flex flex-col gap-3">
            <h3 className="font-extrabold text-[24px]">Doctors</h3>
            <div className="flex gap-4 flex-wrap w-[90%]">
              <DoctorSelector asyncFunction={queryAllDoctors} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;