// TemplatesModal.js
import React, { useState } from "react";

const TemplatesModal = ({ template, closeModal }) => {
  const [copied, setCopied] = useState(false);

  const copyClick = () => {
    navigator.clipboard.writeText(template.note);
    setCopied(true)

    setTimeout(() => {
      setCopied(false)
    }, 2000);
  };

  const handleOverlayClick = (e) => {
    // Ensure that clicks on the outermost div (the overlay) only trigger closeModal
    if (e.target === e.currentTarget) {
      closeModal();
    }
  };

  return (
    <div onClick={handleOverlayClick} className="fixed p-6 overflow-hidden inset-0 z-50 flex items-center justify-center bg-gray-500 bg-opacity-75">
      <div className="">
        <div className="bg-ktx-purple flex justify-center items-center text-center rounded-t-[18px] h-[80px] w-full">
          <h2 className="text-white text-2xl font-bold block w-full">
            {template.name}
          </h2>
          <span className="text-2xl float-right text-white cursor-pointer pr-5 block" onClick={closeModal}>
            &times;
          </span>
        </div>
        <div className="relative overflow-y-auto bg-gray-100 flex">
          <pre className="text-ktx-navy max-w-screen max-h-96 p-4 text-sm rounded">
            {template.note}
          </pre>
          {copied ?
            <div className="sticky top-4 right-4 p-2 max-h-[24px]">
              <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 11.917 9.724 16.5 19 7.5" />
              </svg>
            </div>
            :
            <button className="sticky top-4 right-4 p-2 max-h-[24px]" onClick={copyClick}>
              <svg className="text-transparent-grey w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                <path stroke="currentColor" strokeLinecap="round" stroke-width="2" d="M9 8v3a1 1 0 0 1-1 1H5m11 4h2a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1h-7a1 1 0 0 0-1 1v1m4 3v10a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1v-7.13a1 1 0 0 1 .24-.65L7.7 8.35A1 1 0 0 1 8.46 8H13a1 1 0 0 1 1 1Z" />
              </svg>
            </button>
          }
        </div>
      </div>
    </div>
  );
};

export default TemplatesModal;
