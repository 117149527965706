import { useEffect, useState } from "react";
import { updateModelConfig } from "../../utils/fine-tuning-functions";

export default function ConfigureModelModal({ model, closeModal }) {
  const [modelConfig, setModelConfig] = useState({
    datasetLocation: "",
    hyperparameters: {
      max_seq_length: 2048,
      load_in_4bit: true,
      learning_rate: 0.0002,
      train_batch_size: 2,
      max_steps: 60,
      seed: 3407
    },
    baseModel: ""
  });
  
  useEffect(() => {
    if (model) {
      setModelConfig({
        datasetLocation: model.dataset_location || "",
        hyperparameters: model.hyperparameters || {
          max_seq_length: 2048,
          load_in_4bit: true,
          learning_rate: 0.0002,
          train_batch_size: 2,
          max_steps: 60,
          seed: 3407
        },
        baseModel: model.base_model || ""
      });
    }
  }, [model]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setModelConfig((prevConfig) => ({
      ...prevConfig,
      [name]: value
    }));
  };

  const handleHyperparameterChange = (e) => {
    const { name, value } = e.target;
    setModelConfig((prevConfig) => ({
      ...prevConfig,
      hyperparameters: {
        ...prevConfig.hyperparameters,
        [name]: name === "load_in_4bit" ? value === "true" : value
      }
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      await updateModelConfig(model.id, modelConfig);
      closeModal();
    } catch (error) {
      console.error("Error updating model configuration:", error);
    }
  };

  return (
    <>
      {model !== null ? (
        <div className="fixed left-[28%] top-[28%] w-[50%] h-[50%] flex flex-col items-center justify-center bg-light-purple p-4 rounded-md shadow-md z-10 overflow-y-auto">
          <button className='absolute right-[20px] top-[10px] hover:text-ktx-navy font-bold text-[26px]' onClick={() => closeModal()}>×</button>
          <form onSubmit={handleSubmit} className="flex flex-col w-[80%] mx-auto gap-2 mt-20">
          <h2 className="font-semibold text-[26px] mb-4">Configure Model</h2>
            <div className="mb-4">
              <input
                type="text"
                id="baseModel"
                name="baseModel"
                placeholder="Base Model"
                value={modelConfig.baseModel}
                onChange={handleInputChange}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
            <div className="mb-4">
              <input
                type="text"
                id="datasetLocation"
                name="datasetLocation"
                placeholder="Dataset Location"
                value={modelConfig.datasetLocation}
                onChange={handleInputChange}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
            <div className="mb-4">
              <p className="text-gray-700 text-sm font-bold mb-2">Hyperparameters</p>
              {Object.keys(modelConfig.hyperparameters).map((key) => (
                <div key={key} className="mb-2">
                {key === "load_in_4bit" ? (
                  <select
                    id={key}
                    name={key}
                    value={modelConfig.hyperparameters[key]}
                    onChange={handleHyperparameterChange}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  >
                    <option value="true">True</option>
                    <option value="false">False</option>
                  </select>
                ) : (
                  <input
                    type="text"
                    id={key}
                    name={key}
                    value={modelConfig.hyperparameters[key]}
                    onChange={handleHyperparameterChange}
                    placeholder={key}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                )}
              </div>
              ))}
            </div>
            <div className="flex justify-end">
              <button
                type="button"
                onClick={closeModal}
                className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mr-2"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="bg-ktx-purple hover:bg-ktx-navy text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                Save
              </button>
            </div>
          </form>
        </div>
      ) : null}
    </>
  );
}