import { useState, useEffect } from "react";
import { querySingleDoctorDataToUpdate, querySpecialtiesForDropdown, queryOrgsForDropdown } from "../../utils/functions";
import DoctorData from "./DoctorData";

export default function DoctorFinder({ doctors, doc, setDoc }) {
  const [docQuery, setDocQuery] = useState("");
  const [docSubmitted, setDocSubmitted] = useState(false);
  const [refinedDocs, setRefinedDocs] = useState(null);
  const [getDocData, setGetDocData] = useState(null);
  const [specialties, setSpecialties] = useState(null);
  const [orgs, setOrgs] = useState(null);
  const [accountDeleted, setAccountDeleted] = useState(false);

  useEffect(() => {
    (async () => {
      setSpecialties(await querySpecialtiesForDropdown());
      setOrgs(await queryOrgsForDropdown());
    })()
  }, [doc])

  useEffect(() => {
    if (docQuery && doctors.length) {
      setRefinedDocs(doctors.filter(el => el.user_name.toLowerCase().includes(docQuery.toLowerCase()) || el.user_email.toLowerCase().includes(docQuery.toLowerCase())));
    }

    if (docQuery === "") {
      setRefinedDocs(null);
    }

  }, [docQuery])

  useEffect(() => {
    if (docSubmitted) {
      (async () => {
        setGetDocData(await querySingleDoctorDataToUpdate(doc));
      })();
    }

  }, [docSubmitted])

  const handleSetDoc = (e) => {
    setDoc(e.target.value);
  }

  const resetDocForm = () => {
    setDocSubmitted(false);
    setDoc("");
    setDocQuery("");
    setRefinedDocs(null);
    setGetDocData(null);
  }

  const submitFormForDoc = async (e) => {
    e.preventDefault();

    setDocSubmitted(true);
  }

  return (
    <div className="flex justify-center items-center flex-col">
      {!docSubmitted &&
        <form className="flex gap-1 w-[90%] mb-4 justify-between" onSubmit={submitFormForDoc}>
          <input className="w-[40%] rounded-md" type='text' value={docQuery} onChange={(e) => setDocQuery(e.target.value)} placeholder="Search" />
          <select className="w-[40%] rounded-md" placeholder='Doc' onChange={handleSetDoc} value={doc}>
            <option value="" disabled>Doctor {refinedDocs !== null ? `(${refinedDocs.length})` : `(${doctors.length})`}</option>
            {refinedDocs ?
              refinedDocs && refinedDocs.map((el, i) => (
                <option key={i} value={el.user_id}>{el.user_name}</option>
              )) : doctors && doctors.map((el, i) => (
                <option key={i} value={el.user_id}>{el.user_name}</option>
              ))
            }
          </select>
          <button disabled={doc === ""} className="w-[20%] rounded-md border-sage-green border-[1px] text-sage-green hover:bg-sage-green hover:text-white font-semibold" type="submit">Submit</button>
        </form>
      }
      {accountDeleted && <h3 className="text-ktx-red">Account Deleted</h3>}
      {docSubmitted && (
        <>
          {getDocData ? <DoctorData doctorData={getDocData} resetDocForm={resetDocForm} specialties={specialties} orgs={orgs} setDoctorData={setGetDocData} accountDeleted={accountDeleted} setAccountDeleted={setAccountDeleted} /> :
            <span className="w-6 h-6 border-2 border-dashed rounded-full animate-spin border-ktx-purple"></span>
          }
        </>
      )
      }
    </div>
  )
}