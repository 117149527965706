export default function UpdatePasswordModal({ password, setPassword, handleClosePasswordModal, handleSubmitPassword }) {

  return (
    <div className='fixed shadow-knowtex-shadow rounded-md w-[70%] h-[40%] top-[30%] right-[15%] z-10 bg-white flex flex-col gap-4 p-8 justify-center items-center'>
      <button className='absolute right-[20px] top-[10px] hover:text-ktx-navy font-bold text-[26px] z-[100]' onClick={handleClosePasswordModal}>×</button>
      <h2 className="text-[28px] font-bold text-ktx-navy text-center">Input Doctor's New Password:</h2>
      <input type="text" placeholder="Password" className="border-solid border-[2px] border-ktx-purple w-[50%] p-2 rounded-md" value={password} onChange={(e) => setPassword(e.target.value)}/>
      <button onClick={() => handleSubmitPassword()} className="bg-ktx-purple hover:bg-ktx-navy text-white font-bold px-4 py-2 rounded-md w-[30%] mx-auto">Submit</button>
    </div>
  )
}