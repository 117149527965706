import { useState } from 'react';
import DisplayFullSample from './DisplayFullSample';

export default function DisplayDatasetModal({ dataset, closeModal }) {
  const [openTextModal, setOpenTextModal] = useState(false);
  const [text, setText] = useState("");

  const handleOpenTextModal = (text) => {
    setOpenTextModal(true);
    setText(text);
  }

  const handleCloseTextModal = () => {
    setText("");
    setOpenTextModal(false);
  }

  return (
    <div className="fixed left-[28%] top-[28%] w-[50%] h-[50%] flex flex-col items-center justify-center bg-light-purple p-4 rounded-md shadow-md z-10 overflow-y-auto">
      <button className='absolute right-[20px] top-[10px] hover:text-ktx-navy font-bold text-[26px]' onClick={() => closeModal()}>×</button>
      <h2 className="font-semibold text-[26px] absolute top-4">Dataset Sample</h2>
      {dataset !== null ? (
        <div className="flex flex-col w-full mx-auto gap-1 mt-6">
          {dataset.map((el, i) => (
            <div key={i} className="flex flex-col max-w-[90%] mx-auto">
              <button onClick={() => handleOpenTextModal(el)}>
                <p className="max-w-[90%] truncate">{el}</p>
              </button>
            </div>
          ))}
          {openTextModal && <DisplayFullSample sample={text} closeTextModal={handleCloseTextModal} />}
        </div>
      )
        : (
          <span className="w-6 h-6 border-2 border-dashed rounded-full animate-spin border-ktx-purple mx-auto"></span>
        )
      }
    </div>
  )
}